import { dispatcher, appState } from '../Context';
import ReducerAction from '../Reducer_actions';
import helpers from './helpers';
import UserJSON from '../users';
import socket_IO from './socket_io';
import History from '../History'
import socket from './socket_io';
const Chart = require('chart.js')
const appFunction = {}

// appFunction.userToken = 'lpGTQIAuqFHwsNPwbUQddoAFUMlZaN'
appFunction.userToken = localStorage.getItem("_htoken")


// observer function to monitor chat box
appFunction.chatBoxScroll = null
appFunction.chatBoxObserver = new MutationObserver((mutationsList, observer) => {
   // Use traditional 'for loops' for IE 11
   for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {
         mutation.target.scrollTop = mutation.target.scrollHeight
      }
   }
})


//submit request to add user
appFunction.addUser = (data, callback) => {
   if (!data) return
   //if there's no first name
   if (!data.name) {
      return appFunction.flash("name is required")
   }
   if (!data.phone) {
      return appFunction.flash("phone is required")
   }
   // if (!data.email) {
   //    return appFunction.flash("email is required")
   // }
   if (!data.address) {
      return appFunction.flash("home address is required")
   }
   if (!data.user_type) {
      return appFunction.flash("user_type is required")
   }

   if (data.user_type === "worker") {
      if (!data.skill_1 && !data.skill_2 && !data.skill_3) {
         return appFunction.flash("atleast one worker skill is required")
      }
   }

   let sendData = {
      url: helpers.url.getHollaJobsUsers,
      method: "POST",
      body: data
   }

   appFunction.flash("Loading.....", false)

   helpers.sendRequest(sendData, res => {
      if (res?.error) {
         return appFunction.flash(res?.error)
      } else if (res?.status) {
         appFunction.flash("Record Submitted")
         callback()
      } else {
         appFunction.flash("Could not submitted your request")
      }
   })

}


//function to accept and decline skill
appFunction.toggleJobSkillStatus = (skillID, workerID, status) => {
   let sendData = {
      url: helpers.url.approveSkill,
      method: 'POST',
      body: { skill_id: skillID, worker_id: workerID, status }
   }
   appFunction.flash("Loading....", false)

   //send the request to the server
   helpers.sendRequest(sendData, res => {
      if (res?.status) {
         appFunction.flash("Successful!")
      } else if (res?.error) {
         appFunction.flash(res?.error)
      } else {
         appFunction.flash("An error ocurred!")
      }
   })
}


//for deleting data
appFunction.deleteRecord = (url, requestMethod, data, reloadFunction) => {
   let sendData = {
      url,
      method: requestMethod,
      body: data
   }
   appFunction.flash("Loading....", false)
   //send request to the server
   helpers.sendRequest(sendData, res => {
      appState.modal.type = null
      appState.modal.data = null
      appState.dData.data1 = null
      if (res.status && typeof reloadFunction === 'function') {
         reloadFunction()
      }
      appFunction.flash(res.error ?? ReducerAction.notice.done)
   })
}

//get the settings
appFunction.getSettings = () => {
   let sendData = {
      url: helpers.url.getSettings,
   }
   //send the request
   helpers.sendRequest(sendData, res => {
      appState.dData.type = ReducerAction.screen.settingOthers
      appState.dData.data = res.error ?? res
      appFunction.updateState()
   })
}

//get the settings
appFunction.addSettings = (e) => {
   e.preventDefault()
   let { call_out_charge } = e.target.elements
   call_out_charge = call_out_charge ? call_out_charge.value.trim() : ''

   let sendData = {
      url: helpers.url.addSettings,
      method: "POST",
      body: { call_out_charge }
   }
   appFunction.flash("Loading....", false)
   //send the request
   helpers.sendRequest(sendData, res => {
      appFunction.flash(res.error ?? ReducerAction.notice.done)
   })
}

//function to add/edit kill
appFunction.addEditSkill = (newSkill, category, OldSkill, callback) => {
   //check if there's no skill
   newSkill = newSkill ? newSkill.trim() : ""
   OldSkill = OldSkill ? OldSkill.trim() : ""
   if (!newSkill || newSkill === "") {
      return appFunction.flash("New skill is required")
   }
   if (!category) {
      return appFunction.flash("Category is required")
   }
   let sendData = {
      url: OldSkill ? helpers.url.editSkill : helpers.url.addSkill,
      method: "POST",
      body: { new_skill: newSkill, category, old_skill: OldSkill }
   }
   appFunction.flash("Loading...", false)
   helpers.sendRequest(sendData, res => {
      appState.modal = {}
      if (res.status) {
         appFunction.getSkills()
         if (typeof callback === "function") callback()
      }
      appFunction.flash(res.error ?? ReducerAction.notice.done)
   })
}

//function to load all the skill
appFunction.getSkills = (q, loader, callback) => {
   let sendData = {
      url: `${helpers.url.getSkills}?q=${q ?? ''}`
   }
   if (loader === true) {
      appState.indicator = ReducerAction.indicator.searchQuery
      appFunction.updateState()
   }
   // return
   // send the data to the server
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      if (typeof callback === "function") return callback(res)
      // when it comes come a result (Array instance)
      appState.dData.type = ReducerAction.screen.settingSkill
      appState.dData.data = res.error ?? res[0]
      appFunction.updateState()
   })
}

//function to load all the skill
appFunction.getSkillInfo = (q, loader, category) => {
   let sendData = {
      url: `${helpers.url.getSkillInfo}?q=${q ?? ''}&category=${category ?? ''}`
   }
   if (loader === true) {
      appState.indicator = ReducerAction.indicator.getSkillInfo
      appFunction.updateState()
   }
   // return
   // send the data to the server
   helpers.sendRequest(sendData, res => {
      if (appState.dData.type === ReducerAction.screen.settingSkill) {
         appState.indicator = null
         appState.dData.data1 = res.error ?? res
         appFunction.updateState()
      }
   })
}

//function to submit user verified skill
appFunction.submitVerifiedSkill = () => {
   let data = appState.modal.data
   if (!data) {
      appState.modal.type = null
      appState.modal.data = null
      appFunction.flash("Could not submit request, skill data not found");
      return
   }
   appFunction.flash("Loading...", false);
   let sendData = {
      method: 'POST',
      url: `${helpers.url.submitVerifiedSkill}/${data.user_id}/${data.skill_id}`,
      body: { status: data.status }
   }
   helpers.sendRequest(sendData, res => {
      if (res.message) {
         let index = appState.userData.skills.findIndex(e => e._id === data.skill_id)
         appState.userData.skills[index].verified = data.status
      }
      appFunction.flash(res.message ?? "Server Rejected Submission");
   })
}

//function to upload file to the
appFunction.uploadProfile = (e) => {
   let file = e.target.files && e.target.files[0]
   if (!file) return
   let fileSize = (e.size / 1000000).toFixed(1)
   //check if the type is not image
   if (file.type.indexOf('image/') === -1) {
      return appFunction.flash("Invalid file type")
   }
   //check the file size
   if (fileSize > 5) {
      return appFunction.flash("File size too large")
   }
   let myFormData = new FormData()
   myFormData.append('token', appFunction.userToken)
   myFormData.append('file', file)
   appFunction.flash("Loading...", false)

   //send the request to the server
   fetch(helpers.url.uploadProfilePicture, {
      method: 'POST',
      body: myFormData
   }).then(res => res.json()).then(result => {
      if (result.status) {
         appState.profile.profile_url = result.url
      }
      appFunction.flash(result.error ?? ReducerAction.notice.done)
   })
}

//function to upload file to the
appFunction.uploadSkillDocument = (e) => {
   let file = e.target.files && e.target.files[0]
   let name = e.target.id
   if (!file || !name) return
   let fileSize = (e.size / 1000000).toFixed(1)
   //check if the type is not image
   if (file.type.indexOf('image/') === -1) {
      return appFunction.flash("Invalid file type")
   }
   //check the file size
   if (fileSize > 5) {
      return appFunction.flash("File size too large")
   }
   let myFormData = new FormData()
   myFormData.append('token', appFunction.userToken)
   myFormData.append('name', name)
   myFormData.append('file', file)
   appFunction.flash("Loading...", false)

   let url_id = name.substr(0, name.lastIndexOf('_'));
   //send the request to the server
   fetch(helpers.url.uploadSkillCertificate, {
      method: 'POST',
      body: myFormData
   }).then(res => res.json()).then(result => {
      // console.log(result)
      // return
      if (result.status) {
         document.querySelector('img#img_' + url_id).src = helpers.url.profileImage + result.url
      }
      appFunction.flash(result.error ?? ReducerAction.notice.done)
   })
}

appFunction.getSkillImage = (e) => {
   var loaded = e.target.getAttribute('data-loaded')
   let src = e.target.getAttribute('data-src')
   // let id = e.target.getAttribute('data-id')
   if (loaded === 'yes') {
      return
   }
   e.persist()
   fetch(src).then(response => response.blob())
      .then(images => {
         // Then create a local URL for that image and print it 
         if (images && images.type === 'image/png') {
            // document.querySelectorAll('.cert_li_' + id).forEach(lop => lop.classList.remove('d-none'))
            e.target.setAttribute('data-loaded', 'yes')
            e.target.setAttribute('src', URL.createObjectURL(images))
            // console.log(images)
         }
      })
}

//funtion to delete profile image
appFunction.deleteProfileImage = (url) => {
   if (!url) return appFunction.flash("Image URL not found")
   let sendData = {
      method: 'POST',
      url: helpers.url.deleteProfilePicture,
      body: { token: appFunction.userToken, url }
   }
   appFunction.flash("Loading....", false)
   //send the request to the server
   helpers.sendRequest(sendData, res => {
      if (res.status) {
         appState.profile.profile_url = null
      }
      appFunction.flash(res.error ?? ReducerAction.notice.done)
   })
}

// for monitoring the chat box
appFunction.monitorChatBox = () => {
   let targetChat = document.querySelector(`.lv_cht_box`)
   if (targetChat === null) return
   targetChat.scrollTop = targetChat.scrollHeight //scroll the chat
   const config = { attributes: false, childList: true, subtree: true };
   // start the observation
   appFunction.chatBoxObserver.observe(targetChat, config)
   // disconnect the observation
   // chatBoxObserver.disconnect()
}

//function to get all chat thread
appFunction.getChatThread = (client_email) => {
   if (!client_email) return
   // check the form 
   let sendData = {
      admin_email: appState?.account?.email,
      action: 'getChatThreads',
      client_email
   }
   socket_IO.sendRequest(sendData)
}

// function to send chat
appFunction.sendChat = (e) => {
   e.preventDefault()
   let activeChat = appState.activeChat[0]
   let { input_text } = e.target.elements
   input_text = input_text.value.trim()
   if (input_text.value === "") return
   // check the form 
   let sendData = {
      message: input_text,
      admin_email: appState?.account?.email,
      thread: activeChat?.thread,
      client_email: activeChat?.email,
      action: 'sendChat',
   }
   // check if the message exist then append
   let pos = appState.message.findIndex(e => e.thread === activeChat.thread)
   if (pos > -1) {
      appState.message[pos].chat.push({ sender: sendData.admin_email, message: sendData.message, thread: sendData.thread })
      sendData.chatPos = new Date().toISOString()
      socket_IO.sendRequest(sendData)
   }
   e.target.elements.input_text.value = ""
   appFunction.updateState()
}

//function to accept/check a chat status
appFunction.checkAndAcceptChat = (client_email, status) => {
   if (!client_email) return
   // data to send
   let sendData = {
      admin_email: appState?.account?.email,
      client_email, status, action: 'checkChatStatus',
   }
   // send the request
   socket_IO.sendRequest(sendData)
}

//function to accept/leave a chat chat reply
appFunction.LeaveChat = (thread) => {
   if (!thread) return
   // appState.activeChat[thread].status = email
   let sendData = {
      token: appFunction.userToken,
      thread: thread,
      method: ReducerAction.socket.leaveChat
   }
   // send the request
   socket_IO.sendRequest(sendData)
}

// function to get activate chat response
appFunction.activateChat = (email, thread, threadChat) => {
   if (!email) return
   // check if the chat already exist
   let pos = appState.activeChat.findIndex(e => e.thread === thread);
   if (pos === -1) {
      appState.activeChat = [{ email, thread, status: null, threadChat }]
   }
   // get position
   appFunction.updateState()
   // for a mobile device
   if (window.innerWidth < 768) {
      setTimeout(e => {
         document.querySelector('#pull_livechat').classList.add('pull_livechat')
         window.history.pushState({ page: 'chat' }, '', window.location + `/${thread}`)
      }, 500)
   }
}

// for loading chat
appFunction.getChat = (start_date, end_date, q) => {
   let sendData = {
      action: 'getChat',
      admin_email: appState?.account?.email,
      start_date, end_date, q
   }
   socket_IO.sendRequest(sendData)
}

// function to get all the Jobs
appFunction.getAllJobsApp = (startDate, endDate, q, status, loader, page) => {
   let sendData = {
      url: `${helpers.url.getAllJobsApp}?q=${q ?? ''}&start_date=${startDate ?? ''}&end_date=${endDate ?? ''}&status=${status ?? ''}&page=${page ?? ''}`
   }
   if (loader === true) {
      appState.indicator = ReducerAction.indicator.searchQuery
      appFunction.updateState()
   }
   // send the data to the server
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      if (appState.jobs.app instanceof Array && page) {
         appState.jobs.app = [...appState.jobs.app, ...res]
      } else {
         appState.jobs.app = res.error ?? res
      }
      appFunction.updateState()
   })
}

// function to get all the Jobs
appFunction.getAllJobsUSSD = (startDate, endDate, q, status, loader, page) => {
   let sendData = {
      url: `${helpers.url.getAllJobsUSSD}?q=${q ?? ''}&start_date=${startDate ?? ''}&end_date=${endDate ?? ''}&status=${status ?? ''}&page=${page ?? ''}`
   }
   if (loader === true) {
      appState.indicator = ReducerAction.indicator.searchQuery
      appFunction.updateState()
   }
   // send the data to the server
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      if (appState.jobs.ussd instanceof Array && page) {
         appState.jobs.ussd = [...appState.jobs.ussd, ...res]
      } else {
         appState.jobs.ussd = res.error ?? res
      }
      appFunction.updateState()
   })
}

//function to verifiy a user
appFunction.verifyAccount = (q) => {
   let phoneNumber = /^\d+$/.test(q)
   let sendData = {
      url: helpers.url.verifyUser,
      method: "POST",
      body: {
         email: phoneNumber ? undefined : q,
         phone: phoneNumber ? q : undefined
      }
   }
   appState.indicator = ReducerAction.indicator.searchQuery
   appState.dData.data = null
   appFunction.updateState()
   helpers.sendRequest(sendData, res => {
      appState.indicator = null //clear the indicator
      appState.dData.type = ReducerAction.filters.addExistingUser //add the screen
      appState.dData.data = res.error ?? [res]
      appFunction.updateState()
   })
   //send the request to the backend
}

//function to set user type
appFunction.setUserType = (email, user_type) => {
   let sendData = {
      method: 'POST',
      url: helpers.url.setUserType,
      body: { email, user_type }
   }
   //send the request
   helpers.sendRequest(sendData, res => {
      // console.log(res)
   })
}

// function to handle login
appFunction.processLogin = (e) => {
   e.preventDefault()
   let { email, pwd } = e.target.elements
   email = email && email.value.trim()
   pwd = pwd && pwd.value.trim()
   let submitBTN = document.querySelector("#submit_btn")
   // check the email
   if (email === "" || pwd === "") {
      return document.querySelector('#error').textContent = "All inputs are required"
   }
   // check the password
   if (pwd.length < 6) {
      return document.querySelector('#error').textContent = "Password too short"
   }
   document.querySelector('#error').textContent = ""
   submitBTN.textContent = "Loading..."

   let sendData = {
      method: "POST",
      url: helpers.url.loginUser,
      token: '000000000000000000000000000000',
      body: { email, password: pwd, user_type: "admin" }
   }
   //send the request
   helpers.sendRequest(sendData, (res, error) => {
      if (res.error) {
         //check if the account is not verified
         if (res.verified === false) {
            appState.account.email = email //add the email to the account
            return History.push('/otp')
         }
         submitBTN.textContent = "Sign In"
         document.querySelector('#error').textContent = res.error
      } else if (res.status) {
         if (['admin', 'superadmin', 'support'].indexOf(res?.user_type) === -1) {
            return document.querySelector('#error').textContent = "Unauthorized"
         }
         localStorage.setItem('_uid', JSON.stringify(res))
         appState.auth = true
         appState.account = { ...res, access: {} };
         History.push('/admin')
      } else {
         return document.querySelector('#error').textContent = "Unknow error occured"
      }
   })
}

// function to handle login
appFunction.submitOTP = (e) => {
   e.preventDefault()
   let { otp } = e.target.elements
   otp = otp ? otp.value.trim() : ''
   let email = appState?.account?.email
   let submitBTN = document.querySelector("#submit_btn")
   // check the email
   if (isNaN(otp) || otp.length !== 4) {
      return document.querySelector('#error').textContent = "Invalid OTP"
   }

   document.querySelector('#error').textContent = ""
   submitBTN.textContent = "Loading..."

   let sendData = {
      method: "POST",
      url: helpers.url.submitOTP,
      token: '000000000000000000000000000000',
      body: { email, otp }
   }
   //send the request
   helpers.sendRequest(sendData, res => {
      if (res.error) {
         submitBTN.textContent = "SUBMIT"
         document.querySelector('#error').textContent = res.error
      } else if (res.status) {
         History.push('/password')
      } else {
         return document.querySelector('#error').textContent = "Unknow error occured"
      }
   })
}

// function to handle login
appFunction.submitPassword = (e) => {
   e.preventDefault()
   let { pwd, c_pwd } = e.target.elements
   pwd = pwd ? pwd.value.trim() : ''
   let cPwd = c_pwd ? c_pwd.value.trim() : ''
   let email = appState?.account?.email
   // check if empty
   if (pwd === "") {
      appState.error = 'Password is required'
      appFunction.updateState()
      return
   }
   // check the length is short
   if (pwd.length < 6) {
      appState.error = 'Password must be greater than 5'
      appFunction.updateState()
      return
   }
   // check the length is short
   if (pwd !== cPwd) {
      appState.error = 'Passwords do not match'
      appFunction.updateState()
      return
   }
   // show loading on the button
   let submitBTN = document.querySelector('#submit_btn')
   submitBTN.textContent = "Loading..."
   // sending data
   let sendData = {
      method: "POST",
      url: helpers.url.password,
      body: { password: pwd, email }
   }
   dispatcher({
      type: ReducerAction.error,
      payload: null
   })
   // send the request to the server
   helpers.sendRequest(sendData, res => {
      submitBTN.textContent = "SUBMIT"
      if (res.error) {
         appState.error = res.error
         appFunction.updateState()
         return
      }
      // if successful
      if (res.status) {
         window.localStorage.setItem('_uid', JSON.stringify(res))
         History.push("/admin") // if admin
      } else {
         appState.error = 'Unknown error occurred'
         appFunction.updateState()
      }
   })
}

// function to confirm the login user
appFunction.confirmAdminLogin = () => {
   //get the user data from the storage
   let userData = localStorage.getItem("_uid")
   if (!userData || userData === null) {
      return window.location = '/'
   }
   //parse the data
   userData = typeof userData === 'object' ? userData : JSON.parse(userData)

   let sendData = {
      token: userData?.jToken,
      url: helpers.url.verifyUserJWT
   }
   helpers.sendRequest(sendData, res => {
      // return console.log(res)
      if (res.error) {
         //check if the account is not verified
         if (res.verified === false) {
            appState.account.email = userData?.email //add the email to the account
            return History.push('/otp')
         }
         localStorage.removeItem("_ud")
         return window.location.href = "/"
      } else if (res.status) {
         if (['admin', 'superadmin', 'support'].indexOf(res?.user_type) === -1) {
            localStorage.removeItem("_ud")
            return window.location.href = "/"
         }
         localStorage.setItem('_uid', JSON.stringify(res))
         appState.auth = true
         appState.account = { ...res, access: {} };
         // History.push('/admin')
         // socket.createConnection(userData)
      }
      // load the data
      // appFunction.getHollaJobsUsers();
      // appFunction.getAllDashboardUsers()
      // appFunction.getAllJobs();
      // update the state
      dispatcher({
         type: ReducerAction.isAuthenticated
      })
   })
   // appFunction.getHollaJobsUsers()
}

appFunction.getAnalysis = (date, reload) => {
   // let data = UserJSON
   let sendData = {
      url: `${helpers.url.getHollaJobsAnalysis}?date=${date ?? ''}`
   }
   let an = appState.totalUsers.analysis
   //check if there's already analysis
   if (!reload && an instanceof Array && an.length > 0) {
      return runStatistics(appState.totalUsers.analysis[0])
   }
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      appState.totalUsers.analysis = [res];
      runStatistics(res, date)
   })
}

appFunction.getAnalysisPreUser = (date, reload) => {
   // let data = UserJSON
   let sendData = {
      url: `${helpers.url.getHollaJobsAnalysisPreUsers}?date=${date ?? ''}`
   }
   let an = appState.totalUsers.analysis
   //check if there's already analysis
   if (!reload && an instanceof Array && an.length > 0) {
      return runStatistics(appState.totalUsers.analysis[0])
   }
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      appState.totalUsers.analysis = [res];
      runStatistics(res, date)
   })
}

appFunction.getAnalysisCountPreUsers = (load) => {
   let sendData = {
      url: helpers.url.getAnalysisCountPreUsers
   }
   //check if there's already analysis count
   if (!load && typeof appState.totalUsers.clients === 'number') {
      return
   }
   helpers.sendRequest(sendData, res => {
      //check if return
      if (res?.total) {
         appState.totalUsers.workers = 0
         appState.totalUsers.clients = res?.total
         appState.totalUsers.jobs = 0
         appFunction.updateState()
      }
   })
}

appFunction.getAnalysisCount = (load) => {
   let sendData = {
      url: helpers.url.getAnalysisCount
   }
   //check if there's already analysis count
   if (!load && typeof appState.totalUsers.clients === 'number') {
      return
   }
   helpers.sendRequest(sendData, res => {
      if (res instanceof Array && res.length > 0) {
         appState.totalUsers.workers = res[res.findIndex(e => e._id === 'worker')]?.total
         appState.totalUsers.clients = res[res.findIndex(e => e._id === 'client')]?.total
         appState.totalUsers.jobs = res[res.findIndex(e => e._id === 'job')]?.total
         appFunction.updateState()
      }
   })
}

// function to get the users
appFunction.getHollaJobsUsers = (user_type, q, clearState, page) => {
   // let data = UserJSON
   let sendData = {
      url: `${helpers.url.getHollaJobsUsers}?user_type=${user_type ?? ''}&q=${q ?? ''}&page=${page ?? ''}`
   }

   if (clearState !== false) {
      appState.users = null
      appFunction.updateState()
   }

   helpers.sendRequest(sendData, res => {
      if (appState?.users instanceof Array && res instanceof Array && page) {
         appState.users = [...appState.users, ...res]
      } else {
         appState.users = res.error ?? res
      }
      appFunction.updateState()
   })
}

// to load all the client data
appFunction.getUserJobData = (userID, component, q, date) => {
   let sendData = {
      url: `${helpers.url.getUserJobData}?id=${userID}&component=${component ?? ''}&q=${q ?? ''}&date=${date ?? ''}`
   }
   // send the request to the server
   helpers.sendRequest(sendData, res => {
      appState.indicator = null
      if (component === 'count') {
         //add the request to the user data
         if (appState.userData._id === userID) {
            appState.userData.job.count = res?.error ? [{ client: "An error occured", worker: "An error occured" }] : res
         }
      } else {
         if (appState.userData._id === userID) {
            //add the data when it returns
            if (component === 'request') {
               appState.userData.job.request = res?.error ?? res
            } else if (component === 'offer') {
               appState.userData.job.offer = res?.error ?? res
            }
         }
      }
      appFunction.updateState()
   })
}

//for getting user wallet data
appFunction.getUserWalletBalance = (email) => {
   if (!email) return
   let sendData = {
      url: `${helpers.url.getUserWalletBalance}?email=${email}`
   }
   //get balance
   helpers.sendRequest(sendData, res => {
      if (res.status && res.email === appState?.userData?.email) {
         appState.userData.wallet = res?.error ? [{ balance: "An error occurred", earning: "An error occurred" }] : [res]
      }
      appState.indicator = null
      appFunction.updateState()
   })
}

//for getting user wallet data
appFunction.getUserWalletTransaction = (email) => {
   if (!email) return
   let sendData = {
      url: `${helpers.url.getUserWalletTransaction}?email=${email}`
   }
   //get balance
   helpers.sendRequest(sendData, res => {
      if (res instanceof Array && res.length > 0 && res[0].email === appState?.userData?.email) {
         appState.userData.wallet.transaction = res
         appFunction.updateState()
      }
   })
}

//function to get worker skill
appFunction.getWorkerSkill = (userID) => {
   //if there's no user ID return
   if (!userID) return
   //send the request
   let sendData = {
      url: `${helpers.url.getWorkerSkill}?id=${userID}`
   }

   helpers.sendRequest(sendData, res => {
      //check if there's an error
      if (userID === appState?.userData?._id) {
         appState.userData.worker_data = res?.error ? [] : res
         appFunction.updateState()
      }
   })

}


// function to display all the user data on admin console
appFunction.getMoreUserData = (email) => {
   if (!email) return
   let data = appState.users[appState.users.findIndex(e => e.email === email)]
   appFunction.getUserJobData(data._id, 'count') //get the user data of type count
   appFunction.getUserJobData(data._id, 'request') //get the user data of type count
   if (data?.user_type.indexOf('worker') > -1) {
      appFunction.getUserJobData(data._id, 'offer')
      appFunction.getWorkerSkill(data._id)
   }
   appFunction.getUserWalletBalance(email)
   // appFunction.getUserWalletTransaction(email)
   appState.indicator = ReducerAction.indicator.searchQuery
   data.wallet = []
   data.job = { request: [], offer: [], count: [] }
   appState.userData = data;
   appFunction.updateState()
}
// for toggle side bar width
appFunction.toggleSideBarWidth = (e) => {
   // if it's a mobile view, hide the side bar
   if (window.innerWidth < 768) {
      document.querySelector('.sidebar_pane').classList.add('d-none')
      return
   }
   // if it's not mobile, reduce the side bar size
   let cParent = e.currentTarget.parentElement
   cParent.children[0].classList.toggle('d-none');
   document.querySelector('.sidebar_pane').classList.toggle('sidebar_pane_width');
}

// function to show warning before executing any command
appFunction.showModal = (view, data) => {
   appState.modal.type = view ?? null
   appState.modal.data = data ?? null
   appFunction.updateState()
}

// for closing modal
appFunction.closeModal = () => {
   appState.modal.type = null
   appState.modal.data = null
   appFunction.updateState()
}

// For updating the state
appFunction.updateState = () => {
   dispatcher({ type: ReducerAction.updateState })
}
//function to show flash message
appFunction.flashTimer = null
appFunction.flash = (text, hide = true) => {
   //check the old timer
   if (appFunction.flashTimer) {
      clearTimeout(appFunction.flashTimer)
   }
   dispatcher({ type: ReducerAction.flash, payload: text })
   //if auto hide is enable
   if (hide) {
      appFunction.flashTimer = setTimeout(() => {
         dispatcher({ type: ReducerAction.flash, payload: null })
      }, 3000)
   }
}

// function to log out
appFunction.logOut = () => {
   localStorage.removeItem('_uid')
   window.location = '/'
}

// for creating user
appFunction.createDashBoardUser = (e) => {
   e.preventDefault()
   let action = e.target.getAttribute('data-status')
   // let submitBTN = e.target.elements.submitBTN //get the submit button
   // let submitBTNText = submitBTN.textContent.toLowerCase() // the the current display text on the button
   let { name, email, phone, user_type } = e.target.elements // get all inputs
   name = name ? name.value.trim() : ''
   email = email ? email.value.trim() : ''
   phone = phone ? phone.value.trim() : ''
   user_type = user_type ? user_type.value.trim() : ''
   // check if there are empty
   if (name === "" || phone === "" || email === "") {
      return appFunction.flash("All Inputs are required")
   }
   // check the name string
   if (!/[a-z\- ]/i.test(name)) {
      return appFunction.flash("Only alphabets are allowed for names")
   }
   //check email
   if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      return appFunction.flash("Invalid email")
   }
   //check fot phone
   if (isNaN(phone) || phone.length < 6 || phone.length > 13) {
      return appFunction.flash("Invalid phone")
   }
   // show a flash
   appFunction.flash("Loading...", false)
   let sendData = {
      method: "POST",
      url: action === 'update' ? helpers.url.editUserURL : helpers.url.createUserURL,
      body: { name, email, phone, token: appState?.account?.token, user_type }
   }

   // submit the data
   helpers.sendRequest(sendData, res => {
      appFunction.flash(res.error ?? ReducerAction.notice.done)
      // load new data
      if (res.status) {
         // clear the input
         document.querySelectorAll('#add_user_form .form-control').forEach(e => {
            e.value = ''
            e.checked = false
         })
         // appFunction.getAllDashboardUsers()
      }
   })
}

//function to get user activity
appFunction.getUserActivity = (user_id, date, page) => {
   let sendData = {
      method: "POST",
      url: helpers.url.getUserActivity,
      body: { user_id, date, page }
   }
   // send the request to the server
   helpers.sendRequest(sendData, res => {
      // check if there's an error
      appState.dData.type = ReducerAction.dData.consoleUserActivities
      appState.dData.data = res.error ?? res
      appFunction.updateState()
   })
}

// function to reset password/delete dashboarduser 
// appFunction.resetDashboardUserPassword = (action) => {
//    let email = appState.modal.data;
//    let sendData = {
//       method: "POST",
//       url: action === 'delete' ? helpers.url.deleteUserURL : helpers.url.resetPassword,
//       body: { email }
//    }
//    appFunction.flash("Loading...", false)
//    // send to the server
//    helpers.sendRequest(sendData, res => {
//       appState.modal.type = null
//       appState.modal.data = null
//       if (res.status) {
//          if (action === 'delete') {
//             appState.dUsers.splice(appState.dUsers.findIndex(e => e.email === email), 1);
//          } else if (action === 'reset' && res.self) {
//             appFunction.logOut()
//          }
//       }
//       appFunction.flash(res.error ?? ReducerAction.notice.done);
//    })
// }

//function to run the statatics
const runStatistics = (data, selectedDate) => {
   let dateObj = selectedDate ? new Date(selectedDate) : new Date();
   let day = dateObj.getDate();
   let month = dateObj.getMonth() + 1
   var d = day < 5 ? 5 : day //if date is greater than five give date or five for the chart
   let analysisDays = [] //for adding all date from 01 to current day
   //prepare the date structure
   for (let i = 1; i < (d + 1); i++) {
      analysisDays.push(`${dateObj.getFullYear()}-${month < 10 ? '0' + month : month}-${i < 10 ? '0' + i : i}`)
   }

   let clientTotal = []
   let workerTotal = []
   let jobTotal = []
   let weekDays = []

   // return analysisDays;
   for (let i of analysisDays) {
      clientTotal.push(data?.[i]?.client)
      workerTotal.push(data?.[i]?.worker)
      jobTotal.push(data?.[i]?.job)
      weekDays.push(i.substr(8))
   }

   //today's date
   let todayObj = new Date()
   let todayMnth = todayObj.getMonth() + 1
   let todayDate = todayObj.getDate()
   let today = `${todayObj.getFullYear()}-${todayMnth < 10 ? '0' + todayMnth : todayMnth}-${todayDate < 10 ? '0' + todayDate : todayDate}`
   // let pd = {}
   // pd.totalUsers = {}
   appState.totalUsers.today = {}
   appState.totalUsers.today.job = data?.[today]?.job
   appState.totalUsers.today.worker = data?.[today]?.worker
   appState.totalUsers.today.client = data?.[today]?.client
   appState.totalUsers.today.month = month
   appState.totalUsers.today.show = true
   appState.totalUsers.today.year = dateObj.getFullYear()
   appFunction.updateState()

   appFunction.showDashboardChart(month, weekDays, jobTotal, workerTotal, clientTotal)
}

appFunction.showDashboardChart = (month, weekDays, jobTotal, workerTotal, clientTotal) => {
   //get the line chart canvas
   var ctx = document.querySelector("#analysis-graph");
   if (!ctx) return
   //options
   var options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
         display: false,
         position: "top",
         text: helpers.MonthDays[month],
         fontSize: 18,
         fontColor: "#111"
      },
      legend: {
         display: false,
         position: "bottom",
         labels: {
            fontColor: "#333",
            fontSize: 16
         }
      },
      scales: {
         yAxes: [{
            gridLines: {
               // offsetGridLines: false
            }
         }],
         xAxes: [{
            gridLines: {
               display: false,
               // offsetGridLines: false
            }
         }]
      }
   };

   let graphData = {
      type: "line",
      options: options,
      data: {
         labels: weekDays,
         datasets: [
            {
               label: "Jobs",
               data: jobTotal,
               backgroundColor: '#fff',
               borderColor: "red",
               fill: false,
               // lineTension: 0.2,
               radius: 5,
            },
            {
               label: "Worker",
               data: workerTotal,
               backgroundColor: "#fff",
               borderColor: "#ffdb9a",
               fill: false,
               // lineTension: 0.2,
               radius: 5
            },
            {
               label: "Client",
               data: clientTotal,
               backgroundColor: "#fff",
               borderColor: "#358DCE",
               fill: false,
               // lineTension: 0.2,
               radius: 5
            }
         ]
      }
   }

   if (window.appChart?.line) {
      window.appChart.line.destroy()
   } else {
      window.appChart = {}
   }
   window.appChart.line = new Chart(ctx, graphData);
   window.appChart.line.canvas.parentNode.style.height = '450px';
   window.appChart.line.canvas.parentNode.style.width = '100%';
   appFunction.updateState()
}

// for setting filters 
appFunction.setSelectFilter = (e) => {
   let filterType = e.currentTarget.getAttribute('data-filter') //get the type of filtering done
   let filterValue = e.target.value
   if (filterValue === "" || filterType === null) return //check if there's no value filter
}

// for typing serach
appFunction.filterTypingTimer = null

appFunction.setTypingFilter = (e) => {
   e.preventDefault()
   let filterValue = e.target.elements.search.value.trim() // trim the input
   let filterType = e.target.getAttribute('data-filter') //get the type of filtering done
   // let filterSelect = document.querySelector('#select_filter').value //get the select value
   let filterSelect = appState.modal.screen
   filterValue = filterValue.toLowerCase(); // string the input value to lowercase
   // switching the filter type
   appState.indicator = ReducerAction.indicator.searchQuery
   switch (filterType) {
      case ReducerAction.filters.jobRequests: //filter job request
         let selectStatus = document.querySelector('#select_status')?.value
         let selectDate = document.querySelector('#select_date')?.value
         //split the date
         let dateSplit = selectDate?.replace(/\s/g, '')?.split("-")
         let startDate = ((dateSplit[0]?.split("/"))?.reverse())?.join("-")
         let endDate = ((dateSplit[1]?.split("/"))?.reverse())?.join("-")
         appFunction.getAllJobsApp(startDate, endDate, filterValue, selectStatus, true)
         // appFunction.getAllJobs()
         break;

      case ReducerAction.filters.hollaUsers:
         appFunction.getHollaJobsUsers(filterSelect === ReducerAction.screen.hollaWorkers ? 'worker' : 'client', filterValue)
         break

      case ReducerAction.filters.jobSkills:
         appFunction.getSkills(filterValue, true)

      case ReducerAction.filters.addExistingUser:
         appFunction.verifyAccount(filterValue)
      default:
   }
   appFunction.updateState()
}

// special function to control chat screen on mobile device
window.onpopstate = (e) => {
   if (window.innerWidth < 768 && window.location.href.indexOf('admin/live-chats') > -1) {
      let chatBox = document.querySelector('#pull_livechat'); //get the chat
      // check if the chatbox exist
      if (chatBox) {
         chatBox.classList.remove('pull_livechat')
      }
   }
}

export default appFunction;
