import React, { useContext } from 'react';
import Livechat from './Livechat';
import * as AppComponents from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';



const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Supports</h5>
      <AppComponents.ScreenTabs>
         <AppComponents.ScreenTabsLink name="App" active={defaultScreen} />
         <AppComponents.ScreenTabsLink name="Web" />
      </AppComponents.ScreenTabs>
   </div>
)


export default () => {
   let appState = useContext(AppContext).state
   return (
      <Layout bname="Support" bfa="user" sActive={7}>
         {['superadmin', 'admin', 'support'].indexOf(appState?.account?.user_type_single) === -1 ?
            <AppComponents.NotFound text="Unauthorized" claz="bg-white" /> : <>
               <NavTab defaultScreen={true} />
               <Livechat />
            </>}
      </Layout>
   )
}