import React, { useContext } from 'react';
import * as AppComponents from '../Components';
import NoPhoto from '../../images/no-photo.png';
import Layout from '../Layout';
import helpers from '../../js/functions/helpers';
import AppContext from '../../js/Context';
import appFunction from '../../js/functions/appFunction';
import ReducerAction from '../../js/Reducer_actions';

const HollaUsersearch = () => (
   <div className="d-flex align-items-center pl-4">
      <i className="fa fa-search text-gray"></i>
      <input type="text" id="typing_filter" className="filter-control border-0" placeholder="Search..." data-filter={ReducerAction.filters.hollaUsers} />
   </div>
)


const MailRows = ({ e }) => (
   <tr onClick={txt => appFunction.showMoreUserData(e.email)}>
      <td className="nm_sexxtion nm_mgsbox">
         <div className="newbies_avatar avatar_msgbox">
            <img src={NoPhoto} alt={e.email} />
         </div>
         <h6 className="c-title">Increase Nkanta</h6>
      </td>
      <td>Mail Subject here</td>
      <td>2020-08-23 11:02:07</td>
   </tr>
)

const Mail = ({ users, filterData }) => (
   <AppComponents.AppCard height="600px" title={<HollaUsersearch />} footer={<div className="text-right text-red">Total: {users.length}</div>} padding className="mb-0">
      {filterData && filterData.type === ReducerAction.filters.hollaUsers && (filterData.data === undefined || filterData.data.length === 0) ?
         <AppComponents.NotFound text="No List" /> : users.length === 0 ? <AppComponents.NotFound text="No List" /> :
            <div className="table-data">
               <table>
                  <tbody>
                     {filterData && filterData.type === ReducerAction.filters.hollaUsers ?
                        filterData.data.slice(0, 30).map((e, i) => <MailRows key={i} e={e} />) :
                        users.slice(0, 30).reverse().map((e, i) => <MailRows key={i} e={e} />)
                     }
                  </tbody>
               </table>
            </div>
      }
   </AppComponents.AppCard>
)

const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h4 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Mail</h4>
      <AppComponents.ScreenTabs>
         <AppComponents.ScreenTabsLink name="Inbox" active={defaultScreen} onClick={txt => helpers.switchScreenView()} />
         <AppComponents.ScreenTabsLink name="Sent" onClick={txt => helpers.switchScreenView(ReducerAction.screen.hollaWorkers)} active={screen === ReducerAction.screen.hollaWorkers} />
         <AppComponents.ScreenTabsLink name="Draft" onClick={txt => helpers.switchScreenView(ReducerAction.screen.hollaSponsor)} active={screen === ReducerAction.screen.hollaSponsor} />
         <AppComponents.ScreenTabsLink name="Settings" onClick={txt => helpers.switchScreenView(ReducerAction.screen.hollaPartner)} active={screen === ReducerAction.screen.hollaPartner} />
      </AppComponents.ScreenTabs>
   </div>
)


const Preview = ({ userData }) => (
   <div>
      <div className="nm_sexxtion justify-content-between align-items-center">
         <section className="d-flex">
            <div className="newbies_avatar">
               <img src={NoPhoto} />
            </div>
            <div className="ml-2">
               <h6 className="d-block">Increase Nkanta</h6>
               <small>engr.increase@gmail.com</small>
            </div>
         </section>
         <small>2020-04-02</small>
      </div>

      <section className="mt-4">
         <h5 className="font-bold">Very Important Imformation</h5>
         <div>Dear Sir</div>
         <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi nostrum ex, iusto, officia maiores praesentium eligendi voluptatem quisquam, inventore iure adipisci. Recusandae ad ipsa aperiam beatae consectetur consequuntur accusantium quos.</p>
      </section>
   </div>
)



export default () => {
   const appState = useContext(AppContext).state
   let screenView = appState.modal.screen //get the screent
   let userData = appState.userData //get personal user details
   //filter the data the users
   let users = screenView === ReducerAction.screen.hollaWorkers ? appState.users.filter(e => e.rights === 'worker') :
      screenView === ReducerAction.screen.hollaSponsor ? appState.users.filter(e => e.rights === 'sponsor') :
         screenView === ReducerAction.screen.hollaPartner ? appState.users.filter(e => e.rights === 'partner') :
            appState.users.filter(e => e.rights === 'client')
   return (
      <Layout bname="Mail" bfa="user" sActive={3}>
         {appState.profile.access.mail !== '1' ? <AppComponents.NotFound text="Unauthorized" claz="bg-white" /> : <>
            <NavTab screen={screenView} defaultScreen={users instanceof Array && users.length > 0 && users[0].rights === 'client'} />
            {appState.users === null ? <AppComponents.PageLoader /> : appState.users.length > 0 ?
               <div className="row">
                  <div className="col-xl-6">
                     <Mail users={users} filterData={appState.filterData} />
                  </div>
                  <div className="col-xl-6">
                     {/* {!userData ? <AppComponents.NotFound text="Click to see details" /> : userData instanceof Object ? */}
                     <AppComponents.AppCard height="600px">
                        <Preview userData={userData} />
                     </AppComponents.AppCard>
                     {/* } */}
                  </div>
               </div> : <AppComponents.NotFound text="No Users" />}</>}
      </Layout>
   )
}