import SocketIO from 'socket.io-client';
import appFunction from './appFunction';
import ReducerAction from '../Reducer_actions';
import { appState } from '../Context';

var baseURL = "http://167.71.7.56:7002" //"http://localhost:7002/"
// console.log(appState.profile)
const socket = {}
// create connection 
socket.createConnection = (data) => {
   baseURL += `?token=${data?.token}&app=Holla_Dashboard`
   console.log(baseURL)
   socket.io = SocketIO(baseURL, {
      rejectUnauthorized: false
   })
   // when the socket opens, do the following
   socket.io.on("connect", () => {
      // register the admin
      socket.io.emit("admin", { token: appState?.account?.token, email: appState?.account?.email, action: "authenticate" })
   })

   // for transfering chat
   socket.io.on("admin", (data) => {
      if (data === undefined) return
      socket.handleReply(data)
   })
}

// sending chat to the server
socket.sendRequest = (data) => {
   // console.log("Call to create connection")
   if (socket.io === undefined || !socket.io.connected) {
      socket.createConnection()
      setTimeout(() => {
         socket.io.emit("admin", data)
      }, 800)
   } else {
      socket.io.emit("admin", data)
   }
}


// for processing the functions
socket.handleReply = (data) => {
   // Parse the data if not object
   let body = typeof data === 'object' ? data : JSON.parse(data)
   switch (body.action) {
      case ReducerAction.socket.authenticatedSuccess:
         appFunction.getChat()
         break
      case ReducerAction.socket.chatStatus:
         // check the chat exists
         var pos = appState.activeChat.findIndex(e => e.email === body.client_email);
         if (pos > -1) {
            appState.activeChat[pos].status = body.status //add the status
            appState.activeChat[pos].responder = body.user //add responder if exist
         }
         break;
      case ReducerAction.socket.getChatList:
         appState.message = body.payload // the result
         let chatCount = appState.message.filter(e => e.chatCount > 0); //chek for unseen chat
         appState.totalUsers.liveChat = chatCount.length; // add the unseen chat to the state
         break;

      case ReducerAction.socket.LeaveLiveChat:
         // check if the chat is exist in the user's chat
         var pos = appState.message.findIndex(e => e.thread === body.thread);
         if (pos > -1) {
            appState.message[pos].chat.push({ message: body.user + " left the chat", sender: body.user, left: true, created: new Date().toISOString() });
            // remove the current responder 
            let poss = appState.activeChat.findIndex(e => e.thread === body.thread);
            if (poss > -1) {
               // if the responder is the login user, clear
               if (appState.activeChat[poss].responder === appFunction.userToken) {
                  appState.activeChat[poss].responder = null
                  appState.activeChat[poss].status = "left"
               }
            }
         }
         break;
         // case ReducerAction.socket.getChatList:
         //    if (appState.activeChat instanceof Array && appState.activeChat[0] && appState.activeChat[0].thread === body.thread) {
         //       appState.dData.type = ReducerAction.dData.chatThread
         //       appState.dData.data = body.payload
         //    }
         break;
      case ReducerAction.socket.newChat:
         // get the position of the chat if exist
         var pos = appState.message.findIndex(e => e.email === body?.payload?.email)
         if (pos > -1) {
            appState.message[pos] = body.payload;
         } else {
            // if the message property not array, convert to array
            if (!appState.message instanceof Array) {
               appState.message = [];
            }
            appState.message.push(body.payload) //add the new chat if not exist
         }
         break;
      case ReducerAction.socket.getChatThread:
         var pos = appState.activeChat.findIndex(e => e.email === body.client_email);
         if (pos > -1) {
            appState.dData.type = ReducerAction.dData.chatThread
            appState.dData.data = body.payload
         }

      default:
   }
   appFunction.updateState()
}

export default socket;