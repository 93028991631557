import React, { useContext } from 'react';
import { PageLoader, ScreenTabs, ScreenTabsLink } from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import HollaUser from './HollaUser';



const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Manage Users</h5>
      <ScreenTabs>
         <ScreenTabsLink name="Clients" active={defaultScreen} onClick={txt => helpers.switchScreenView()} />
         <ScreenTabsLink name="Workers" onClick={() => helpers.switchScreenView(ReducerAction.screen.hollaWorkers, null, "users")} active={screen === ReducerAction.screen.hollaWorkers} />
         <ScreenTabsLink name="Admins" onClick={() => helpers.switchScreenView(ReducerAction.screen.hollaAdmin, null, "users")} active={screen === ReducerAction.screen.hollaAdmin} />
         <ScreenTabsLink name="Supervisors" onClick={() => helpers.switchScreenView(ReducerAction.screen.hollaSupervisor, null, "users")} active={screen === ReducerAction.screen.hollaSupervisor} />
         {/* <ScreenTabsLink name="Sponsors" onClick={txt => helpers.switchScreenView(ReducerAction.screen.hollaSponsor)} active={screen === ReducerAction.screen.hollaSponsor} /> */}
         {/* <ScreenTabsLink name="Partners" onClick={txt => helpers.switchScreenView(ReducerAction.screen.hollaPartner)} active={screen === ReducerAction.screen.hollaPartner} /> */}
      </ScreenTabs>
   </div>
)

export default () => {
   let appState = useContext(AppContext).state
   let screenView = appState?.modal?.screen;
   //get the screen clicked
   let selectUser = screenView === ReducerAction.screen.hollaWorkers ? 'worker' :
      screenView === ReducerAction.screen.hollaPartner ? 'partner' :
         screenView === ReducerAction.screen.hollaSupervisor ? 'supervisor' :
            screenView === ReducerAction.screen.hollaAdmin ? 'admin' :
               screenView === ReducerAction.screen.hollaSponsor ? 'sponsor' : 'client';
   //get the totalusers selected
   let userTypeTotal = appState.totalUsers[selectUser + 's']
   return (
      <Layout bname="Skill Management" bfa="user" sActive={1}>
         <NavTab screen={screenView} defaultScreen={selectUser === 'client'} />
         <HollaUser
            data={appState?.users}
            userType={selectUser}
            userData={appState.userData}
            indicator={appState.indicator} />
         {/* <HollaUser data={appState?.users instanceof Array ? appState?.users : []} userType={selectUser} /> */}
      </Layout>
   )
}