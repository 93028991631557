import React from 'react';
import Layout from '../Layout';
import * as AppComponent from '../Components';
import AppIcons from '../icons';
import History from '../../js/History';
import appFunction from '../../js/functions/appFunction';



const RewardSearch = () => (
   <>
      <select className="filter-control" style={{ maxWidth: '230px' }}>
         <option value="all">Select Reward Status</option>
         <option value="worker">Pending</option>
         <option value="client">Redeemed</option>
      </select>
      <input type="text" className="filter-control" style={{ maxWidth: '230px' }} placeholder="Search Reward" />
   </>)

const RewardsRow = ({ e, i }) => (
   <tr onClick={appFunction.closeMoreUserDataMenu}>
      <td>11</td>
      <td>Increase Nkanta</td>
      <td>engr.increase@gmail.com</td>
      <td>09034566444</td>
      <td>{e}</td>
      <td className="pymt_status">
         <span className="o">Pending</span>
      </td>
      <td>2020/04.02 12:00</td>
      <td></td>
      <td className="more_user_data">
         <div className="svg_icon more_user_data" onClick={appFunction.showEllipsisMenu}>
            <AppIcons.ellipsisV />
            <ul className="d-none more_user_data" style={i ? { bottom: '0', top: 'unset' } : { top: '0' }}>
               <li onClick={() => History.push('/admin/reward/736364')}>View Activities</li>
               <li>Mark As Redeemed</li>
            </ul>
         </div>
      </td>
   </tr>
)
const Rewards = () => (
   <AppComponent.AppCard title="Redeem Requests" component={<RewardSearch />} padding="no" footer={<AppComponent.Pagination />}>
      <AppComponent.AppTable headings={["Roll", "Name", "Email", "Phone", "User Type", "Status", "Requested On", "Redeemed On", "More"]}>
         {['Woker', 'Client', 'Client', 'Client', 'Worker', 'Worker', 'Client', 'Worker', 'Client'].map((e, i) => <RewardsRow key={i} e={e} i={i} />)}
      </AppComponent.AppTable>
   </AppComponent.AppCard>
)

export default () => (
   <Layout bname="All Redeemers" sActive={5}>
      <Rewards />
   </Layout>
)