import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import UserManagement from '../pages/Holla-user/index';
import JobManagement from '../pages/JobRequest/index';
import Redeemers from '../pages/Rewards/Redeemers';
import RewardPoints from '../pages/Rewards/RewardPoints';
import AppContext from '../js/Context';
import { RunAuthentication } from './Login';
import Message from '../pages/Mail/Message';
import Media from '../pages/SocialMedia';
import ConsoleUsers from '../pages/Console-users';
import Support from '../pages/Support';
import appFunction from '../js/functions/appFunction';
import Profile from './Profile';
import Settings from './Settings';
import PreUserDashboard from './PreUserDashboard';
import SkillManagement from './SkillManagement';

export default (e) => {
   const appState = useContext(AppContext).state;
   React.useEffect(() => {
      appFunction.confirmAdminLogin();
   }, [])
   return (
      <>{!appState.auth ? <RunAuthentication /> :
         <Switch>
            <Route exact path="/admin/pre-users" component={PreUserDashboard} />
            <Route exact path="/admin/holla-users" component={UserManagement} />
            <Route exact path="/admin/mail/" component={Message} />
            <Route exact path="/admin/console-users" component={ConsoleUsers} />
            <Route exact path="/admin/social-media" component={Media} />
            <Route exact path="/admin/jobs" component={JobManagement} />
            <Route exact path="/admin/support" component={Support} />
            <Route exact path="/admin/skills" component={SkillManagement} />
            <Route exact path="/admin/reward-redeemers" component={Redeemers} />
            <Route exact path="/admin/reward-points" component={RewardPoints} />
            <Route exact path="/admin/profile" component={Profile} />
            <Route exact path="/admin/settings" component={Settings} />
            <Route path="/admin" component={Dashboard} />
         </Switch>
      }</>
   )
}