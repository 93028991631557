
const ReducerAction = {}
ReducerAction.error = "error";
ReducerAction.flash = "Flash_message";
ReducerAction.isAuthenticated = "user_is_authenticated"
ReducerAction.loader = "loader_display";
ReducerAction.usersLoaded = "users_loaded";
ReducerAction.updateState = "update_state";
ReducerAction.accountLoaded = "user_data_loaded";
ReducerAction.dUsersLoaded = "dashboard_users_loaded";
ReducerAction.singleUserLoaded = ""
ReducerAction.walletLoaded = "wallet_loaded";
ReducerAction.setFilterItem = "set_filter_items";
ReducerAction.allJobsLoaded = "all_jobs_loaded";
ReducerAction.DEFAULT_PASSWORD = "default_password_detected";
// for modal
ReducerAction.modal = {}
ReducerAction.modal.resetPassword = "reset_dashboard_user_password";
ReducerAction.modal.deleteAction = "delete_action";
ReducerAction.modal.verifyWorkerSkill = "verify_worker_skill";
ReducerAction.modal.addNewSkill = "add_new_skill";
ReducerAction.modal.jobsUSSDAssignWorker = "assign_worker_to_ussd_job";
ReducerAction.modal.jobsUSSDStatus = "update_job_ussd_status";

// for dData
ReducerAction.dData = {}
ReducerAction.dData.consoleUserActivities = "show_console_user_activity"
ReducerAction.dData.chatThread = "show_chat_thread"

ReducerAction.indicator = {}
ReducerAction.indicator.dashboardChart = "show_loader_on_dashboard_chrt"
ReducerAction.indicator.searchQuery = "show_loader_for_search"
ReducerAction.indicator.getSkillInfo = "show_loader_for_getting_skill_info"

// For filters  
ReducerAction.filters = {}
ReducerAction.filters.hollaUsers = "filter_holla_users"
ReducerAction.filters.jobRequests = "filter_job_requests"
ReducerAction.filters.jobOffers = "filter_job_offers"
ReducerAction.filters.jobSkills = "filter_job_skill"
ReducerAction.filters.addExistingUser = "filter_existing_user"

// for socket 
ReducerAction.socket = {}
ReducerAction.socket.inputError = "input_error"
ReducerAction.socket.serverError = "server_error"
ReducerAction.socket.success = "success"
ReducerAction.socket.newChat = "new_chat"
ReducerAction.socket.typingChat = "typing_chat"
ReducerAction.socket.chatSave = "chat_save"
ReducerAction.socket.chatStatus = "check_status_status"
ReducerAction.socket.adminReplyChat = "admin_reply_chat"
ReducerAction.socket.getChatList = "get_live_chat_list"
ReducerAction.socket.LeaveLiveChat = "leave_live_chat"
ReducerAction.socket.getChatThread = "get_chat_thread"
ReducerAction.socket.acceptLiveChat = "accept_live_chat"
ReducerAction.socket.authenticatedSuccess = "authentication_success"


//for screen view
ReducerAction.screen = {}
ReducerAction.screen.dashboardStat = "show_dashboard_stat"
//USERS
ReducerAction.screen.hollaWorkers = "show_holla_workers"
ReducerAction.screen.hollaSponsor = "show_holla_sponsors"
ReducerAction.screen.hollaPartner = "show_holla_partners"
ReducerAction.screen.hollaSupervisor = "show_holla_supervisor"
ReducerAction.screen.hollaAdmin = "show_holla_admin"
ReducerAction.screen.addNewUser = "show_user_new_add"
ReducerAction.screen.addExistingUser = "show_user_existing_add"
//ADMIN USERS
ReducerAction.screen.consoleUserActivities = "show_console_user_activities"
//SOCIAL MEDIA
ReducerAction.screen.socialMediaTwitter = "social_media_twitter"
ReducerAction.screen.socialMediaFacebook = "social_media_facebook"
ReducerAction.screen.socialMediaInstagram = "social_media_instagram"
ReducerAction.screen.socialMediaInstagram = "social_media_instagram"
//SETTING
ReducerAction.screen.settingSkill = "setting_skill"
ReducerAction.screen.settingOthers = "setting_others"
//
ReducerAction.screen.jobsUSSD = "show_ussd_jobs"

//for notice 
ReducerAction.notice = {}
ReducerAction.notice.done = "Done"

export default ReducerAction;