import React, { useContext } from 'react';
import MaterialTable from 'material-table'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { AppCard, Modal, ArrowDropDownMenu, TableMaterialIcon } from '../Components';
import appFunction from '../../js/functions/appFunction';
import helpers from '../../js/functions/helpers';
import { Settings, ArrowDropDown, } from '@material-ui/icons'
import ReducerAction from '../../js/Reducer_actions';


const JobRequestSearch = () => {
   const [cState, setCState] = React.useState({
      dateModal: null,
      selectionRange: {
         startDate: new Date(),
         endDate: new Date(),
         show: null
      }
   })
   //for closing the date picker
   const closeDatePicker = (e) => {
      if (e === 'close') {
         cState.selectionRange.show = null
      } else {
         cState.selectionRange.show = true
      }
      setCState(prev => ({ ...prev, dateModal: null }))
   }
   const setSelectedDate = ({ range1 }) => {
      setCState(prev => ({ ...prev, selectionRange: { startDate: range1.startDate, endDate: range1.endDate } }))
   }

   return (
      <div>
         <div className="d-flex  align-items-center">
            <i className="fa fa-search text-gray job-srch-icon"></i>
            <form onSubmit={appFunction.setTypingFilter} data-filter={ReducerAction.filters.jobRequests} className="w-100">
               <input type="text" name="search" style={{ maxWidth: 300 }} className="filter-control pl-5" placeholder="Job Title, Location or Phone" />
            </form>
            <select id="select_status" className="filter-control" style={{ maxWidth: '150px' }} data-filter={ReducerAction.filters.jobRequests} onChange={appFunction.setSelectFilter}>
               <option value="">Job Status</option>
               <option value="pending">Pending</option>
               <option value="completed">Completed</option>
               <option value="cancel">Canceled</option>
            </select>
            <input type="date" id="select_date" className="filter-control" style={{ maxWidth: '160px' }} placeholder="Start Date" />
         </div>
      </div>
   )
}

// For detail preview
const PaymentDataTable = ({ data }) => (
   <MaterialTable
      style={{ minWidth: 750, marginTop: 1 }}
      data={data ? [data] : []}
      title="Payment"
      columns={[
         {
            field: 'method',
            title: "Method",
            headerStyle: { fontWeight: 'bold' },
            // render: () => data?.method,
            cellStyle: { textTransform: 'capitalize' }
         },
         {
            field: 'material',
            title: "Material",
            render: () => helpers.formatMoney(data?.material),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'labour',
            title: "Labour",
            render: () => helpers.formatMoney(data?.labour),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'transport',
            title: "Transport",
            render: () => helpers.formatMoney(data?.transport),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            title: 'Service Charge',
            field: "service_charge",
            render: () => helpers.formatMoney(data?.service_charge),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'total',
            title: "Total",
            render: () => helpers.formatMoney(data?.total),
            headerStyle: { fontWeight: 'bold' },
         },
      ]}
      options={{ search: false, paging: false }}
   />
)

const UserDataTable = ({ data, title, clientID }) => (
   <MaterialTable
      title={title}
      style={{ minWidth: 750, marginTop: 10 }}
      data={data ?? []}
      columns={[
         {
            title: 'User Type',
            field: 'user_type',
            cellStyle: { fontWeight: 'bold', textTransform: 'capitalize' },
            render: rowData => String(rowData?._id) === String(clientID) ? "Client" : "Worker",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'name',
            title: "Name",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'phone',
            title: "Phone",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'email',
            title: "Email",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'location.address',
            title: "Address",
            headerStyle: { fontWeight: 'bold' },
         }
      ]}
      options={{ search: false, paging: false }}
   />
   // console.log(data)
)

const AssignWorker = ({ jobData }) => (
   <div>
      <div className="pl-3 pt-3">
         <span className="font-bold">Job Skill:</span> {jobData?.title}</div>
      <div className="pl-3 pt-1">
         <span className="font-bold">Job Location:</span> {jobData?.location}</div>
      {/*
      <div className="d-flex  align-items-center">
         <i className="fa fa-search text-gray job-srch-icon"></i>
         <form onSubmit={appFunction.setTypingFilter} data-filter={ReducerAction.filters.jobRequests} className="w-100">
            <input type="text" name="search" style={{ maxWidth: 700 }} className="filter-control pl-5" placeholder="Job Title, Location or Phone" />
         </form>
      </div> */}
      {/* <AppCard> */}
      <MaterialTable
         components={{
            Toolbar: () => (<div className="d-flex px-3 align-items-center">
               <i className="fa fa-search text-gray job-srch-icon"></i>
               <form onSubmit={appFunction.setTypingFilter} data-filter={ReducerAction.filters.jobRequests} className="w-100">
                  <input type="text" name="search" style={{ maxWidth: 700 }} className="filter-control pl-5" placeholder="Job Title, Location or Phone" />
               </form>
            </div>)
         }}
         columns={[
            {
               title: "Name",
               field: 'name'
            },
            {
               title: "Location",
               field: 'address'
            },
         ]}
         data={[]}
         options={{
            search: false,
            showTitle: false,
            headerStyle: { fontWeight: 'bold' }
         }}
         title="Assign Worker"
      />
      {/* </AppCard> */}
   </div>
)

const UpdateJobStatus = ({ jobData }) => (
   <div className="px-3 w-100">
      <div className=" pt-3">
         <span className="font-bold">Job Skill:</span> {jobData?.title}</div>
      <div className="pt-1">
         <span className="font-bold">Job Location:</span> {jobData?.location}</div>

      <select className="form-control w-100">
         <option>Update Status</option>
         <option>Completed</option>
         <option>Canceled</option>
      </select>
   </div>
)



export default ({ data, indicator }) => {

   React.useEffect(() => {
      appFunction.getAllJobsUSSD()
   }, [])
   const [cState, setCState] = React.useState({ openModal: false, modalScreen: null, jobData: {}, showDropDown: false })

   let tableSetting = {
      headerStyle: { fontWeight: 'bold' },
      cellStyle: { textTransform: 'capitalize' }
   }
   let dataLen = data?.length
   return (
      <AppCard>
         <MaterialTable
            isLoading={data === null}
            icons={TableMaterialIcon}
            components={{
               Toolbar: props => (<JobRequestSearch />)
            }}
            columns={[
               {
                  title: 'Job Title',
                  field: 'title',
                  ...tableSetting
               },
               {
                  title: 'Job Location',
                  field: 'location',
               },
               {
                  title: 'Phone',
                  field: 'phone',
               },
               {
                  title: 'Job Status',
                  field: 'phone',
                  cellStyle: { minWidth: 250 },
                  render: e => (
                     <div className="pymt_status">
                        <i style={{ textTransform: 'capitalize' }} className={e.status_code === 10 ? 'f' :
                           e.status_code === 7 ? 'c' : 'p'}> {helpers.runJobStage(e?.status_code)}</i>
                     </div>),
                  ...tableSetting

               },
               {
                  title: 'Date',
                  field: 'createdAt',
                  render: e => helpers.calculatePostDate(e.createdAt),
               },
               {
                  title: "Action",
                  cellStyle: { position: 'relative' },
                  render: rowData => (
                     <React.Fragment>
                        <div className="cursor-pointer"
                           onClick={() => setCState(d => ({ ...d, showDropDown: rowData._id }))}>
                           <ArrowDropDown />
                        </div>
                        {cState.showDropDown === rowData._id && <ArrowDropDownMenu onClick={e => {
                           switch (e) {
                              case 'Assign a Worker':
                                 setCState(d => ({
                                    ...d, showDropDown: null, openModal: true, jobData: rowData,
                                    modalScreen: ReducerAction.modal.jobsUSSDAssignWorker
                                 }))
                                 break;
                              default:
                                 setCState(d => ({
                                    ...d, showDropDown: null, openModal: true, jobData: rowData,
                                    modalScreen: ReducerAction.modal.jobsUSSDStatus
                                 }))

                           }
                        }} menuList={["Assign a Worker", "Update Status"]} />}
                     </React.Fragment>
                  )
               }
            ]}
            options={{
               pageSize: 10,
               pageSizeOptions: [10, 20, 30, 40, 50],
               headerStyle: { textTransform: 'capitalize', fontWeight: 'bold' }

            }}
            data={data instanceof Array ? data : []}
            onSelectionChange={e => console.log(e)}
            onChangePage={(page, pageSize) => {
               //check the page and the page size
               if ((pageSize * page) >= (dataLen - pageSize)) {
                  //get page numba
                  let pageN = Math.ceil(dataLen / 25)
                  if (pageN > 1) {
                     appFunction.getAllJobsUSSD(undefined, undefined, undefined, undefined, true, pageN)
                  }
               }
            }}
            title="Jobs USSD" />
         {cState.openModal &&
            <Modal onOverlayClick={() => setCState(d => ({ ...d, openModal: false }))}
               modalContainerStyle={{ backgroundColor: "#fff", padding: 0 }} modalContentStyle={{ alignItems: "flex-start" }}>
               {cState.modalScreen === ReducerAction.modal.jobsUSSDAssignWorker ?
                  <AssignWorker jobData={cState?.jobData} /> : ReducerAction.modal.jobsUSSDStatus ?
                     <UpdateJobStatus jobData={cState?.jobData} /> : null
               }
            </Modal>
         }

      </AppCard>
   )
}
