import React from 'react';
import appFunction from '../../js/functions/appFunction';
import ReducerAction from '../../js/Reducer_actions';
import * as AppComponents from '../Components';



export default ({ data }) => {
   React.useEffect(e => {
      appFunction.getSettings()
   }, [])
   return (
      data?.type === ReducerAction.screen.settingOthers ?
         <div className="row">
            <div className="col-6">
               <AppComponents.AppCard>
                  {data.data instanceof Array ?
                     <form onSubmit={appFunction.addSettings}>
                        <strong>Call Out Charge</strong>
                        <input type="number" min={0} className="form-control" name="call_out_charge" defaultValue={data.data[0]?.call_out_charge} />
                        <button className="btn btn-primary">Submit</button>
                     </form> :
                     <AppComponents.NotFound text={data.data} />
                  }
               </AppComponents.AppCard>
            </div>
         </div> :
         <AppComponents.PageLoader />
   )
}