import React, { useContext } from 'react';
import * as AppComponents from '../Components';
import NoPhoto from '../../images/no-photo.png';
import AppIcons from '../icons';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import appFunction from '../../js/functions/appFunction';
import AppContext from '../../js/Context';




const ChatRows = ({ e }) => (
   <div className="profil profil-cht mb-0" onClick={() => appFunction.activateChat(e.email, e.thread)}>
      <div>
         <img src={NoPhoto} alt="Profile" />
      </div>
      <div>
         <h6 className={e.chatCount ? "mb-0 font-bold" : "mb-0"}>
            <span>{e.name}</span>
            {/* <small className={`cht-badge ${e.rights ?? "red"}`}>{e.user_type ?? "Unknown"}</small> */}
         </h6>
         <h6 className="mb-0 text-gray">{e.chat[e.chat.length - 1].sender !== e.email ?
            'reply: ' + e.chat[e.chat.length - 1].message : e.chat[e.chat.length - 1].message}</h6>
      </div>
      <div className="float-right">
         {e.chatCount > 0 && <span className="lv_cht_inctr">{e.chatCount}</span>}
      </div>
   </div>
)

const AllChats = ({ chats }) => (
   <div>
      <div className="d-flex align-items-center pl-4">
         <i className="fa fa-search text-gray"></i>
         <input type="text" id="typing_filter" className="filter-control border-0" placeholder="Search name or email..." data-filter={ReducerAction.filters.hollaUsers} />
      </div>
      <div className="lv_cht_box p-0">
         {chats.map((e, i) => <ChatRows key={i} e={e} />)}
      </div>
   </div>
)

const ChatForm = ({ thread }) => (
   <form data-thread={thread} onSubmit={appFunction.sendChat}>
      <div className="d-flex profil-cht bm">
         <input type="text" className="form-control mb-0 border-0 p-0" name="input_text" placeholder="Type something..." />
         <div className="ml-2">
            <button className="btn btn-primary" type="submit">Send</button>
         </div>
      </div>
   </form>
)

const ChatStatus = ({ e }) => (
   <div className="p-3 text-center">
      {e.status === null ? <small>Checking status...</small> :
         e.status === 'offline' ? <small className="text-red">User is offline</small> :
            e.status === "none" ? <button className="btn btn-sm btn-primary" onClick={w => appFunction.checkAndAcceptChat(e.email, 'accept')}>Reply Chat</button> :
               <small><b>{e.responder}</b> is on this chat</small>
      }
   </div>
)

const LiveChatIcon = ({ activeChat }) => (
   <div className="lv_cht_nm">
      {activeChat.responder === appFunction.userToken && <span title="Leave Chat" onClick={() => appFunction.LeaveChat(activeChat.thread)}><i className="fa fa-sign-out"></i> </span>}
   </div>
)

const ChatHead = ({ data, activeChat }) => (
   <div className="profil profil-cht bm">
      <div>
         <img src={NoPhoto} alt="Profile" />
      </div>
      <div onClick={e => appFunction.getChatThread(data?.email)}>
         <h6 className="mb-0">
            <span>{data?.name}</span>
            <small className={`cht-badge ${data?.user_type ?? "red"}`}>{data?.user_type ?? "Unknown"}</small>
         </h6>
         <small>{data?.thread}</small>
      </div>
      <LiveChatIcon activeChat={activeChat} />
   </div>
)

const ChatBox = ({ e }) => {
   const appState = useContext(AppContext).state
   // get the active chat clicked
   let activeChat = e
   let userData = activeChat.threadChat === true ?
      appState.dData.data[appState.dData.data.findIndex(ed => ed.thread === activeChat.thread)] :
      appState.message[appState.message.findIndex(ed => ed.thread === e.thread)] //get the chats
   let chtDate = null
   let showDate = null
   let PD = null
   React.useEffect(d => {
      appFunction.checkAndAcceptChat(e.email)
      appFunction.monitorChatBox()
   }, [e.thread])
   return (
      <div>
         <ChatHead activeChat={activeChat} data={userData} />
         <div className="lv_cht_box">
            {userData && userData.chat && userData.chat.map((e, i) => {
               PD = e.left ? showDate : e.created && e.created.substr(0, 10)
               chtDate = showDate !== PD ? null : showDate;
               showDate = PD
               if (e.sender === activeChat.email) {
                  return <React.Fragment key={i}>{chtDate === null && <div className="cht_time_indica"><i>{helpers.calculatePostDate(e.created, 'date-only')}</i></div>}
                     <div className="_cht1">
                        <span>{e.message}
                           <small className="cht_time">{helpers.getPostTime(e.created)}</small>
                        </span>
                     </div></React.Fragment>
               } else {
                  return <React.Fragment key={i}>
                     {chtDate === null && <div className="cht_time_indica"><i>{helpers.calculatePostDate(e.created, 'date-only')}</i></div>}
                     <div key={i} className="_cht2">
                        <span className={e.left ? "left_chat" : ""}>
                           {e.left ? (e.sender === appFunction.userEmail ? "you left the chat" : e.message) : e.message}
                           {!e.left && <small className="cht_time">{e.created ? helpers.getPostTime(e.created) : <i className="text-orange">posting...</i>}</small>}
                        </span>
                        {!e.left && <div className="respndr"><i>{e.sender === appFunction.userEmail ? "you" : e.sender}</i></div>}
                     </div></React.Fragment>
               }
            })}
         </div>
         {activeChat.status === 'me' ? <ChatForm thread={e.thread} /> : <ChatStatus e={activeChat} />}
      </div>
   )
}


const ChatBoxPane = ({ message, activeChat }) => (
   <AppComponents.AppCard bodyStyle={{ padding: 0 }}>
      <div className="row">
         <div className="col pr-0 cht_list">
            <AllChats chats={message} />
         </div>
         <div className="col pl-0">
            {activeChat.length > 0 ? <ChatBox e={activeChat[0]} /> :
               <AppComponents.NotFound claz="h-100" text="Click to preview" />}
         </div>
      </div>
   </AppComponents.AppCard>
)

const ChatTicket = ({ data }) => (
   <AppComponents.AppCard bodyStyle={{ padding: 0 }}>
      <div>
         <div className="profil-cht bm svg_icon justify-content-end">
            <span onClick={e => helpers.clearTickerView()}>
               <AppIcons.close />
            </span>
         </div>
         <div className="profil profil-cht bm profil-ticket">
            <div>
               <img src={NoPhoto} alt="Profile" />
            </div>
            <div>
               <h6 className="mb-0">
                  <span>{data[0].name}</span>
               </h6>
               <small>{data[0].email}</small>
               <p>
                  <small className={`cht-badge ${data[0].user_type ?? "red"}`}>{data[0].user_type ?? "Unknown"}</small>
               </p>
            </div>
         </div>
         <div className="d-flex align-items-center pl-4">
            <i className="fa fa-search text-gray"></i>
            <input type="text" id="typing_filter" className="filter-control border-0" placeholder="Search with date" data-filter={ReducerAction.filters.hollaUsers} />
         </div>
         <div style={{ overflow: 'auto' }}>
            <AppComponents.AppTable headings={['Ticker', 'Date']}>
               {data.map((e, i) => (
                  <tr key={i}>
                     <td className="cursor-pointer" onClick={w => appFunction.activateChat(e.email, e.thread, true)}>{e.thread}</td>
                     <td>{helpers.calculatePostDate(e.createdAt, 'date-only')}</td>
                  </tr>
               ))}
            </AppComponents.AppTable>
         </div>
      </div>
   </AppComponents.AppCard>
)

export default () => {
   const appState = useContext(AppContext).state
   return (
      appState.message === null ? <AppComponents.PageLoader /> : appState.message.length === 0 ?
         <AppComponents.NotFound claz="bg-white" text="No Chats" /> :
         <div className="row">
            <div className="col-md">
               <ChatBoxPane message={appState.message} activeChat={appState.activeChat} />
            </div>
            {appState.dData.type === ReducerAction.dData.chatThread &&
               <div className="col-md-4 pl-0">
                  <ChatTicket data={appState.dData.data} />
               </div>
            }
         </div>
   )
}