import React, { useContext } from 'react';
import * as AppComponents from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import OtherSettings from './OtherSettings';



const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Setup Management</h5>
      <AppComponents.ScreenTabs>
         <AppComponents.ScreenTabsLink name="Service Charge" active={true} onClick={w => helpers.switchScreenView(ReducerAction.screen.settingOthers)} />
      </AppComponents.ScreenTabs>
   </div>
)


export default () => {
   let appState = useContext(AppContext).state
   let screen = appState.modal.screen
   return (
      <Layout bname="Setup Management" bfa="user" sActive={8}>
         <NavTab screen={screen} />
         <OtherSettings data={appState.dData} />
      </Layout>
   )
}