import React from 'react';

export default () => {
   React.useEffect(e => {
      var bodyTag = document.querySelector('.sc_plugin')
      var scpt = document.createElement('script')
      scpt.src = "https://platform.twitter.com/widgets.js"
      scpt.defer = true
      bodyTag.append(scpt);
   }, [])
   return (
      <div className="sc_plugin">
         <a className="twitter-timeline" data-height="550" data-width="500" data-theme="light" href="https://twitter.com/hollajobs?ref_src=twsrc%5Etfw">Loading Tweets...</a>
      </div>
   )
};