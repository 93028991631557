import React from 'react';
import './css/Style.css'
import { Route, Router, Switch } from 'react-router-dom';
import { Login, OTP, ChangePassword } from './pages/Login';
import { AppProvider } from './js/Context'
import Admin from './pages';
import History from './js/History';

export default () => (
   <AppProvider>
      <Router history={History}>
         <Switch>
            <Route path="/admin" component={Admin} />
            <Route path="/otp" component={OTP} />
            <Route path="/password" component={ChangePassword} />
            <Route component={Login} />
         </Switch>
      </Router>
   </AppProvider>
)