import React from 'react';
import AppContext from '../../js/Context';
import appFunction from '../../js/functions/appFunction';
import helpers from '../../js/functions/helpers';
import ReducerAction from '../../js/Reducer_actions';
import * as AppComponents from '../Components';
import AppIcons from '../icons';
import Layout from '../Layout';


const ProfileCard = ({ data }) => (
   <AppComponents.AppCard padding>
      <div className="row">
         <div className="col-md-5 table-data">
            <table>
               <tbody>
                  <tr><td className="c-title">Name:</td><td className="text-gray">{data && data.name}</td></tr>
                  <tr><td className="c-title">Username/Email:</td><td className="text-gray">{data && data.email}</td></tr>
                  <tr><td className="c-title">Access:</td>
                     {data.access ? <td>{data.access.admin === '1' && 'Admin,'}{data.access.support === '1' && 'Support,'}{data.access.mail === '1' && 'Mail,'}{data.access.social === '1' && 'Socail'}</td> : <td></td>}
                  </tr>
                  <tr><td className="c-title">Phone:</td><td className="text-gray">{data && data.phone}</td></tr>
                  <tr><td className="c-title">Created:</td><td className="text-gray">{data && helpers.calculatePostDate(data.created)}</td></tr>
               </tbody>
            </table>
            <div className="mt-3 p-3 text-center">
               <p>if you want to change your password, click the button below to reset</p>
               <button className="btn btn-sm btn-light" onClick={() => appFunction.showModal(ReducerAction.modal.resetPassword, data && data.email)}>Reset Password</button>
            </div>
         </div>
         <div className="col-md-7">
            <div className="profil profil-d">
               <div>
                  <img src={AppComponents.ProfilePhoto} alt="Profile" />
               </div>
            </div>
            <div className="svg_icon profil-d-svg mb-2">
               <label className="m-0 p-1">
                  <input type="file" className="d-none" onChange={appFunction.uploadProfile} />
                  <AppIcons.Camera />
               </label>
               {data.profile_url &&
                  <span className="m-0 d-inline-block p-1" onClick={e => appFunction.showModal(ReducerAction.modal.deleteAction, data.profile_url)}>
                     <AppIcons.delete />
                  </span>}
            </div>
         </div>
      </div>
   </AppComponents.AppCard>
)

export default () => {
   const appState = React.useContext(AppContext).state
   return (
      <Layout sActive={8} bname="Profile">
         <div className="my-4">
            <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Profile</h5>
         </div>
         <ProfileCard data={appState.profile} />
         {appState.modal.type === ReducerAction.modal.resetPassword &&
            <AppComponents.Modal>
               <AppComponents.NoticeCard text="Do you wannt to reset your password?" onClick={() => appFunction.resetDashboardUserPassword('reset')} />
            </AppComponents.Modal>
         }
         {appState.modal.type === ReducerAction.modal.deleteAction &&
            <AppComponents.Modal>
               <AppComponents.NoticeCard text="Are you sure you want to remove photo?" onClick={() => appFunction.deleteProfileImage(appState.modal.data)} />
            </AppComponents.Modal>
         }
      </Layout>
   )
}