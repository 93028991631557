import React from 'react';
import AppContext from '../../js/Context';
import helpers from '../../js/functions/helpers';
import ReducerAction from '../../js/Reducer_actions';
import * as AppComponents from '../Components';
import Layout from '../Layout';
import FacebookPage from './Facebook';
import Twiiter from './Twitter';



const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Social Media</h5>
      <AppComponents.ScreenTabs>
         <AppComponents.ScreenTabsLink name="Facebook" active={defaultScreen} onClick={e => helpers.switchScreenView()} />
         <AppComponents.ScreenTabsLink name="Twitter" onClick={e => helpers.switchScreenView(ReducerAction.screen.socialMediaTwitter)} active={screen === ReducerAction.screen.socialMediaTwitter} />
         {/* <AppComponents.ScreenTabsLink name="Instagram" onClick={e => helpers.switchScreenView(ReducerAction.screen.socialMediaInstagram)} active={screen === ReducerAction.screen.socialMediaInstagram} /> */}
      </AppComponents.ScreenTabs>
   </div>
)

export default () => {
   let appState = React.useContext(AppContext).state
   let activeScreen = appState.modal.screen
   let itsDefault = [ReducerAction.screen.socialMediaInstagram, ReducerAction.screen.socialMediaTwitter].indexOf(activeScreen) === -1
   return (
      <Layout bname="Social Media" bfa="user" sActive={4}>
         <NavTab screen={activeScreen} defaultScreen={itsDefault} />
         <AppComponents.AppCard>
            {activeScreen === ReducerAction.screen.socialMediaTwitter ? <Twiiter /> :
               <FacebookPage />
            }
         </AppComponents.AppCard>
      </Layout>
   )
}