import React from 'react';

export default () => (
   <div className="sc_plugin">
      <div class="iframe-fb-container">
         <iframe title="facebook_ifram" id="fbc_id" className="iframe-fb border" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/Hollajobs&tabs=timeline&width=700&height=600&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=312880510002233"></iframe>
      </div>
   </div>

)
