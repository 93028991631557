import React, { useContext } from 'react';
import MaterialTable from 'material-table'
import { AppCard, Modal, PageLoader, TableMaterialIcon } from '../Components';
import appFunction from '../../js/functions/appFunction';
import helpers from '../../js/functions/helpers';
import { Settings } from '@material-ui/icons'
import ReducerAction from '../../js/Reducer_actions';

const ModalScreen = {
   addUser: 'add_user_modal_screen',
   seeMoreData: 'see_more_user_screen'
}
// For detail preview
const Skills = ({ data, userData }) => (
   <MaterialTable
      title="Skills"
      isLoading={!data}
      data={data?.skills ?? []}
      style={{ marginTop: 1, minWidth: 600 }}
      columns={[
         {
            field: 'skill_id.name', title: "Name",
            cellStyle: { textTransform: 'capitalize' },
            headerStyle: { fontWeight: "bold" }
         },
         {
            field: 'status', title: "Status",
            headerStyle: { fontWeight: "bold" },
            render: rowData => <div style={{
               textTransform: 'capitalize', fontWeight: 'bold',
               color: rowData.status === "approved" ? "green" : "gray",
            }}>{rowData?.status}</div>
         },
         {
            field: 'experience', title: "Years of Exp.",
            headerStyle: { fontWeight: "bold" }
         },
         {
            field: 'created', title: "Created",
            headerStyle: { fontWeight: "bold" },
            render: rowData => helpers.calculatePostDate(rowData.created)
         },
         {
            title: "Action",
            headerStyle: { fontWeight: "bold" },
            render: rowData => (
               <button className={rowData?.status === "pending" ? "btn btn-primary" : "btn btn-danger"}
                  onClick={() => appFunction.toggleJobSkillStatus(rowData.skill_id._id, data.user_id, rowData?.status === "pending" ? "approved" : "pending")}>
                  {rowData.status === 'pending' ? 'Approve' : 'Decline'}</button>

            )
         }
      ]}
      options={{ search: false, paging: false }}
   />
)

// For detail preview
const JobDataTable = ({ data, }) => (
   <MaterialTable style={{ minWidth: 600 }}
      data={data ?? []}
      columns={[
         {
            field: 'client',
            title: "Job Requested",
            headerStyle: { fontWeight: "bold" }
         },
         {
            field: 'worker',
            title: "Job Done",
            headerStyle: { fontWeight: "bold" }
         }
      ]} options={{ search: false, toolbar: false, paging: false }}
   />
)

// For detail preview
const WalletDataTable = ({ data }) => (
   <MaterialTable
      title="Wallet"
      style={{ marginTop: 1, minWidth: 600 }}
      data={data ?? []}
      columns={[
         {
            field: 'balance', title: "Balance",
            headerStyle: { whiteSpace: "nowrap", fontWeight: "bold" },
            render: rowData => <div>N{helpers.formatMoney(rowData.balance)}</div>
         },
         {
            field: 'earning', title: "Earning",
            headerStyle: { whiteSpace: "nowrap", fontWeight: "bold" },
            render: rowData => <div>N{helpers.formatMoney(rowData.earning)}</div>

         }
      ]}
      options={{ search: false, paging: false }}
   />
)

const AddUserForm = ({ parentState }) => {
   const [cState, setCState] = React.useState({
      user_type: "", name: "", email: "", phone: "",
      address: "", guarantor_name: "", guarantor_phone: "", guanrantor_phone: "",
      skill_1: "", skill_2: "", skill_3: "", dob: "", guanrantor_name: ""
   })
   const [skillList, setSkillList] = React.useState([])

   const getSelectedSkillID = (value) => {
      //if there's not value return
      if (!value) return ""
      let skillID = skillList?.filter(e => e.name === value)[0]
      //if the name exist
      return (skillID && skillID._id) ? skillID._id : ""
   }

   React.useEffect(() => {
      appFunction.getSkills(null, null, res => {
         console.log(res)
         setSkillList(res.error ? [] : res[0].data)
      })
   }, [])



   return (
      <div className="row">
         <div className="col-md-12">
            <h4>Add User</h4>
         </div>
         <div className="col-md-6">
            <label htmlFor="name">Name</label>
            <input className="form-control" name="name" id="name" onChange={txt => {
               cState.name = txt.target.value
            }} />
         </div>
         <div className="col-md-6">
            <label htmlFor="phone">Phone</label>
            <input className="form-control" name="phone" type="number" onChange={txt => {
               cState.phone = txt.target.value
            }} />
         </div>
         <div className="col-md-6">
            <label htmlFor="email">Email</label>
            <input className="form-control" id="email" name="email" onChange={txt => {
               cState.email = txt.target.value
            }} />
         </div>
         <div className="col-md-6">
            <label htmlFor="dob">Date of Birth</label>
            <input className="form-control" id="dob" onBlur={txt => {
               let splt = txt.target.value.trim().split("/")
               cState.dob = `${splt[2]}-${splt[1]}-${splt[0]}`
               console.log(cState.dob)
            }} />
         </div>
         <div className="col-md-6">
            <label htmlFor="address">Home Address</label>
            <input className="form-control" name="address" onChange={txt => {
               cState.address = txt.target.value
            }} />
         </div>
         <div className="col-md-6">
            <label htmlFor="email">User Type</label>
            <select id="user_type" className="form-control" onChange={txt => {
               let valueD = txt.target.value
               setCState(d => ({ ...d, user_type: valueD }))
            }}>
               <option value="">Select</option>
               <option value="worker">Worker</option>
               <option value="client">Client</option>
            </select>
         </div>
         {cState?.user_type === "worker" &&
            <React.Fragment>
               <div className="col-md-12">
                  <label htmlFor="email">Skills</label>
                  <div className="d-flex">
                     <input className="form-control" list="skill-1" onBlur={txt => {
                        cState.skill_1 = getSelectedSkillID(txt.target.value)

                     }} />
                     <datalist id="skill-1">
                        {skillList?.map(e => <option key={e?._id} value={e?.name} />)}
                     </datalist>
                     <input className="form-control" list="skill-2" style={{ margin: '0 8px' }} onBlur={txt => {
                        cState.skill_2 = getSelectedSkillID(txt.target.value)
                     }} />
                     <datalist id="skill-2">
                        {skillList?.map(e => <option key={e?._id} value={e?.name} />)}
                     </datalist>
                     <input className="form-control" list="skill-3" onBlur={txt => {
                        cState.skill_3 = getSelectedSkillID(txt.target.value)

                     }} />
                     <datalist id="skill-3">
                        {skillList?.map(e => <option key={e?._id} value={e?.name} />)}
                     </datalist>
                  </div>
               </div>
               <div className="col-md-6">
                  <label htmlFor="gurn">Guarantor's Name</label>
                  <input className="form-control" name="address" id="gurn" min={0} onChange={txt => {
                     cState.guarantor_name = txt.target.value
                  }} />
               </div>
               <div className="col-md-6">
                  <label htmlFor="gurp">Guarantor's Phone</label>
                  <input className="form-control" name="address" id="gurp" type="number" min={0} onChange={txt => {
                     cState.guarantor_phone = txt.target.value
                  }} />
               </div>
            </React.Fragment>
         }
         <div className="col-md-12">
            <button className="btn btn-primary" onClick={() => appFunction.addUser(cState, () => parentState(d => ({ ...d, modal: null })))}>Submit</button>
         </div>
      </div>
   )
}


export default ({ data, userType, userData, indicator }) => {
   React.useEffect(() => {
      appFunction.getHollaJobsUsers(userType, null)
   }, [userType])
   const [cState, setCState] = React.useState({ modal: false, })
   let tableSetting = {
      headerStyle: { fontWeight: 'bold' },
      cellStyle: { textTransform: 'capitalize' }
   }
   let dataLen = data?.length
   return (
      <AppCard>
         <MaterialTable
            isLoading={data === null}
            icons={TableMaterialIcon}
            actions={[{
               icon: 'add',
               isFreeAction: true,
               onClick: () => setCState(d => ({ ...d, modal: ModalScreen.addUser }))
            }]}
            columns={[
               {
                  title: 'Name',
                  field: 'name',
                  ...tableSetting
               },
               {
                  title: 'Email',
                  field: 'email',
                  headerStyle: { fontWeight: 'bold' },
               },
               {
                  title: 'Phone',
                  field: 'phone',
                  ...tableSetting

               },
               {
                  title: 'Referral Code',
                  field: 'refcode',
                  ...tableSetting

               },
               {
                  title: 'Rating',
                  field: 'rating',
                  ...tableSetting

               },
               {
                  title: 'Address',
                  field: 'address',
                  ...tableSetting

               },
               {
                  title: 'Email Confirmed',
                  field: 'is_confirmed',
                  type: "boolean",
                  ...tableSetting

               },
               {
                  title: 'Registered',
                  field: 'createdAt',
                  render: rowData => helpers.calculatePostDate(rowData.createdAt),
                  ...tableSetting,
                  cellStyle: { textTransform: "initial" }
               },
               {
                  field: 'state',
                  title: "State",
                  ...tableSetting,
               },
               {
                  field: 'city',
                  title: "City",
                  ...tableSetting,
               },
               {
                  field: 'business_location',
                  title: "Business Addr",
                  ...tableSetting,
               },
               {
                  field: 'dob',
                  title: "Birth Date",
                  ...tableSetting,
               },
               {
                  field: 'bank_name',
                  title: "Bank Name",
                  ...tableSetting,
               },
               {
                  field: 'bank_account',
                  title: "Bank Account",
                  ...tableSetting,
               },
               {
                  title: "Action",
                  headerStyle: { fontWeight: 'bold' },
                  render: e => <div className="cursor-pointer" onClick={() => {
                     appFunction.getMoreUserData(e.email)
                     setCState(d => ({ ...d, modal: ModalScreen.seeMoreData }))
                  }}> <Settings /></div>
               }
            ]}
            options={{
               pageSize: 10,
               pageSizeOptions: [10, 20, 30, 40, 50]
            }}
            data={data instanceof Array ? data : []}
            onSelectionChange={e => console.log(e)}
            onChangePage={(page, pageSize) => {
               //check the page and the page size
               if ((pageSize * page) >= (dataLen - pageSize)) {
                  //get page numba
                  let pageN = Math.ceil(dataLen / 25)
                  if (pageN > 1) {
                     appFunction.getHollaJobsUsers(userType, undefined, false, pageN)
                  }
               }
            }}
            title="Users Management" />
         {cState.modal === ModalScreen.seeMoreData &&
            <Modal
               modalContainerStyle={{ backgroundColor: "#fff" }}
               modalContentStyle={{ alignItems: 'flex-start' }}
               onOverlayClick={() => setCState(d => ({ ...d, modal: false }))}>
               <JobDataTable data={userData?.job?.count} />
               {userData?.user_type?.indexOf('worker') > -1 && <Skills data={userData.worker_data} />}
               <WalletDataTable data={userData?.wallet} />
               {indicator === ReducerAction.indicator.searchQuery &&
                  <div style={{ position: 'absolute', zIndex: 11, width: '100%', top: 0, marginTop: 100, left: 0 }} >
                     <PageLoader style={{ position: 'relative' }} />
                  </div>
               }
            </Modal>
         }
         {cState.modal === ModalScreen.addUser &&
            <Modal
               modalContainerStyle={{ backgroundColor: "#fff" }}
               modalContentStyle={{ alignItems: 'flex-start', maxWidth: 700 }}
               onOverlayClick={() => setCState(d => ({ ...d, modal: false }))}>
               <AddUserForm parentState={setCState} />
            </Modal>
         }

      </AppCard>
   )
}
