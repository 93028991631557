import React from 'react';
const AppIcons = {}

AppIcons.arrow = () => <svg enableBackground="new 0 0 256 256" height="256px" id="Layer_1" version="1.1" viewBox="0 0 256 256" width="256px"><path d="M179.199,38.399c0,1.637-0.625,3.274-1.875,4.524l-85.076,85.075l85.076,85.075c2.5,2.5,2.5,6.55,0,9.05s-6.55,2.5-9.05,0  l-89.601-89.6c-2.5-2.5-2.5-6.551,0-9.051l89.601-89.6c2.5-2.5,6.55-2.5,9.05,0C178.574,35.124,179.199,36.762,179.199,38.399z" /></svg>

// AppIcons.ellipsisV = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
//    width="400.000000pt" height="400.000000pt" viewBox="0 0 400.000000 400.000000" preserveAspectRatio="xMidYMid meet">
//    <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
//       <path className="prefix__cls-1" d="M1890 3746 c-138 -39 -254 -148 -301 -283 -24 -71 -26 -207 -3 -284 29 -101 120 -211 212 -258 170 -87 379 -60 513 66 99 92 139 189 139 333 0 138 -42 234 -143 327 -107 98 -275 138 -417 99z" />
//       <path className="prefix__cls-1" d="M1893 2385 c-73 -20 -127 -49 -180 -98 -102 -94 -143 -189 -143 -332 0 -143 41 -238 143 -332 163 -150 431 -150 594 0 102 94 143 189 143 332 0 143 -41 238 -143 332 -107 98 -269 137 -414 98z" />
//       <path className="prefix__cls-1" d="M1890 1024 c-120 -32 -227 -125 -284 -244 -26 -56 -31 -79 -34 -167 -6 -152 32 -248 137 -346 168 -157 434 -157 602 0 105 98 143 194 137 346 -3 88 -8 111 -34 167 -95 200 -312 301 -524 244z" />
//    </g>
// </svg>

AppIcons.Reload = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
   preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path d="M2692 4787 c-49 -18 -97 -61 -118 -104 -16 -32 -19 -68 -22 -238 l-4 -201 -141 -12 c-798 -70 -1456 -586 -1706 -1337 -209 -629 -82 -1327 337 -1846 85 -106 271 -282 378 -357 703 -499 1636 -491 2324 19 108 80 284 251 365 354 226 290 367 627 410 986 14 113 -41 203 -138 228 -57 14 -503 15 -577 1 -112 -21 -209 -98 -230 -182 -28 -114 -76 -243 -116 -315 -197 -348 -603 -559 -990 -514 -136 16 -231 44 -349 101 -613 301 -760 1106 -293 1605 111 119 276 224 428 273 72 24 224 52 276 52 l34 0 0 -205 c0 -230 4 -250 63 -302 61 -54 150 -67 222 -34 22 10 285 201 584 424 612 457 612 456 619 572 5 69 -14 123 -62 177 -25 27 -1065 806 -1123 840 -40 24 -128 32 -171 15z" />
   </g>
</svg>


AppIcons.arrowDown = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
   preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path d="M838 3587 c-15 -7 -149 -133 -298 -281 -279 -278 -290 -292 -290 -371 0 -16 9 -48 19 -70 24 -50 2172 -2199 2214 -2215 36 -13 118 -13 154 0 42 16 2190 2165 2214 2215 21 46 24 87 8 136 -14 42 -528 564 -576 585 -38 17 -110 18 -150 3 -16 -5 -369 -351 -800 -782 l-773 -772 -772 772 c-432 431 -785 777 -801 782 -37 14 -115 13 -149 -2z" />
   </g>
</svg>


AppIcons.ellipsisV = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width="400.000000pt" height="400.000000pt" viewBox="0 0 400.000000 400.000000" preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path className="prefix__cls-1" d="M1890 3746 c-138 -39 -254 -148 -301 -283 -24 -71 -26 -207 -3 -284 29 -101 120 -211 212 -258 170 -87 379 -60 513 66 99 92 139 189 139 333 0 138 -42 234 -143 327 -107 98 -275 138 -417 99z" />
      <path className="prefix__cls-1" d="M1893 2385 c-73 -20 -127 -49 -180 -98 -102 -94 -143 -189 -143 -332 0 -143 41 -238 143 -332 163 -150 431 -150 594 0 102 94 143 189 143 332 0 143 -41 238 -143 332 -107 98 -269 137 -414 98z" />
      <path className="prefix__cls-1" d="M1890 1024 c-120 -32 -227 -125 -284 -244 -26 -56 -31 -79 -34 -167 -6 -152 32 -248 137 -346 168 -157 434 -157 602 0 105 98 143 194 137 346 -3 88 -8 111 -34 167 -95 200 -312 301 -524 244z" />
   </g>
</svg>

AppIcons.delete = () => <svg xmlns="http://www.w3.org/2000/svg" width="9.445" height="13" viewBox="0 0 9.445 13">
   <g>
      <g data-name="Group 326">
         <g data-name="Group 325">
            <path className="prefix__cls-1" d="M9.021 1.3H6.699V.424A.424.424 0 0 0 6.275 0H3.17a.424.424 0 0 0-.424.424V1.3H.424A.424.424 0 0 0 0 1.724v1.3a.424.424 0 0 0 .424.424h8.6a.424.424 0 0 0 .424-.424v-1.3a.424.424 0 0 0-.427-.424zm-3.17 0H3.593V.848H5.85z" data-name="Path 596" transform="translate(-70.007) translate(70.007)" />
         </g>
      </g>
      <g data-name="Group 328">
         <g data-name="Group 327">
            <path className="prefix__cls-1" d="M0 .003l.352 8.29a.424.424 0 0 0 .424.406h6.718a.424.424 0 0 0 .424-.406l.351-8.29zm2.852 6.972a.424.424 0 1 1-.848 0V1.726a.424.424 0 1 1 .848 0zm1.705 0a.424.424 0 1 1-.848 0V1.726a.424.424 0 1 1 .848 0zm1.705 0a.424.424 0 1 1-.848 0V1.726a.424.424 0 1 1 .848 0z" data-name="Path 597" transform="translate(-70.007) translate(70.596 4.304)" />
         </g>
      </g>
   </g>
</svg>

AppIcons.info = () =>
   <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="1200.000000pt" height="1200.000000pt" viewBox="0 0 1200.000000 1200.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
         <path d="M5730 10874 c-1711 -100 -3232 -1073 -4040 -2584 -759 -1421 -758 -3176 2 -4584 534 -988 1363 -1752 2378 -2189 1304 -561 2794 -525 4074 98 698 340 1312 854 1788 1495 472 636 785 1401 897 2190 33 230 41 329 47 580 18 655 -93 1287 -331 1900 -58 149 -149 350 -203 450 -99 184 -123 225 -200 350 -445 716 -1090 1322 -1827 1717 -578 309 -1154 485 -1825 559 -129 14 -625 26 -760 18z m720 -778 c821 -95 1575 -419 2198 -943 740 -623 1233 -1474 1406 -2423 48 -265 60 -414 60 -730 0 -316 -12 -465 -60 -730 -308 -1692 -1632 -3017 -3324 -3324 -259 -47 -413 -60 -730 -60 -318 0 -470 12 -730 60 -833 153 -1584 547 -2180 1144 -668 667 -1074 1511 -1187 2465 -24 207 -24 680 0 885 60 506 190 951 408 1390 439 882 1178 1578 2086 1964 403 171 826 274 1298 316 144 12 596 4 755 -14z" />
         <path d="M5704 8980 c-156 -41 -290 -116 -397 -223 -156 -155 -234 -336 -244 -563 -11 -253 72 -467 250 -643 172 -170 384 -249 631 -238 146 7 210 23 341 87 211 103 359 278 435 515 31 97 39 319 16 420 -75 324 -315 570 -626 645 -113 27 -304 27 -406 0z" />
         <path d="M5535 6709 c-555 -21 -1016 -38 -1022 -38 -10 -1 -13 -47 -13 -194 l0 -194 208 5 c159 3 228 1 296 -11 108 -19 203 -63 241 -112 58 -77 56 -11 53 -1345 l-3 -1225 -29 -58 c-33 -69 -88 -109 -169 -127 -29 -6 -161 -14 -294 -17 l-243 -6 0 -194 0 -193 1470 0 1470 0 0 193 0 194 -207 6 c-313 8 -413 39 -464 143 l-24 49 -3 1583 -2 1582 -128 -1 c-70 -1 -581 -19 -1137 -40z" />
      </g>
   </svg>

AppIcons.edit = () => <svg xmlns="http://www.w3.org/2000/svg" width="13.065" height="13" viewBox="0 0 13.065 13">
   <g>
      <path className="prefix__cls-1" d="M12.059 8.419a.325.325 0 0 0-.325.325v2.889a.977.977 0 0 1-.976.976H1.627a.977.977 0 0 1-.976-.976V3.155a.977.977 0 0 1 .976-.976h2.889a.326.326 0 1 0 0-.651H1.627A1.629 1.629 0 0 0 0 3.155v8.48a1.629 1.629 0 0 0 1.627 1.627h9.13a1.629 1.629 0 0 0 1.627-1.627v-2.89a.325.325 0 0 0-.325-.325zm0 0" data-name="Path 598" transform="translate(0 -.261)" />
      <path className="prefix__cls-1" d="M12.255.69a1.464 1.464 0 0 0-2.071 0L4.379 6.5a.325.325 0 0 0-.084.143L3.53 9.394a.325.325 0 0 0 .4.4l2.756-.764a.325.325 0 0 0 .143-.084l5.805-5.806a1.466 1.466 0 0 0 0-2.071zM5.088 6.707l4.751-4.751 1.532 1.532L6.62 8.239zm-.306.614l1.224 1.224-1.693.469zm7.4-4.639l-.345.345-1.532-1.532.345-.345a.813.813 0 0 1 1.15 0l.382.382a.815.815 0 0 1-.005 1.15zm0 0" data-name="Path 599" transform="translate(0 -.261)" />
   </g>
</svg>

AppIcons.deActivate = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={{ width: '26px', height: '26px' }} viewBox="0 0 910.000000 512.000000" preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path d="M4375 5114 c-255 -25 -415 -57 -615 -124 -399 -131 -717 -327 -1010 -620 -219 -219 -340 -389 -485 -685 -125 -254 -198 -485 -242 -765 -22 -142 -25 -559 -5 -685 54 -341 128 -573 274 -858 268 -523 676 -906 1233 -1159 195 -88 476 -167 715 -199 123 -17 497 -17 620 0 460 63 874 233 1250 515 109 82 341 307 423 413 272 346 457 762 533 1199 26 145 28 181 28 409 1 269 -11 378 -60 585 -81 343 -249 697 -467 989 -93 124 -335 367 -459 459 -367 274 -772 444 -1213 508 -98 14 -440 26 -520 18z m296 -744 c243 -18 456 -73 654 -170 107 -53 215 -117 215 -128 0 -10 -2499 -2507 -2503 -2502 -19 24 -88 149 -126 232 -217 461 -223 1020 -16 1490 269 611 850 1027 1506 1077 57 5 111 9 119 9 8 1 76 -3 151 -8z m1493 -1001 c41 -82 89 -191 105 -243 208 -649 35 -1371 -443 -1849 -272 -272 -625 -451 -1011 -512 -119 -19 -373 -22 -495 -5 -205 27 -385 84 -578 181 -75 38 -146 76 -157 84 -19 15 40 75 1230 1265 912 912 1252 1246 1261 1239 7 -5 46 -77 88 -160z" />
   </g>
</svg>

AppIcons.tick = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="prefix__cls-1" viewBox="0 0 750.000000 750.000000"
   preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,750.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path d="M5815 6237 c-81 -21 -127 -48 -183 -107 -31 -31 -243 -337 -499 -720 -455 -681 -753 -1124 -1266 -1890 -161 -239 -411 -612 -556 -827 -144 -216 -266 -393 -271 -393 -4 0 -272 269 -596 598 -324 328 -608 611 -632 629 -163 119 -394 76 -532 -98 -102 -129 -110 -322 -18 -450 51 -71 1586 -1625 1638 -1659 135 -86 331 -71 450 37 37 33 165 216 477 682 235 350 499 744 588 876 698 1035 1836 2737 1852 2770 84 165 28 370 -130 478 -106 73 -222 99 -322 74z" />
   </g>
</svg>

AppIcons.menu = () => <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 'unset' }} viewBox="0 0 1024.000000 1024.000000" preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
      <path d="M1105 8301 c-222 -64 -392 -238 -449 -458 -21 -80 -21 -246 0 -327 43 -167 168 -325 320 -404 153 -79 -244 -72 4144 -72 4388 0 3991 -7 4144 72 109 57 207 155 263 263 55 107 73 181 73 305 0 124 -18 198 -73 305 -56 108 -154 206 -262 262 -156 80 262 73 -4151 72 -3726 0 -3952 -1 -4009 -18z" />
      <path d="M1105 5741 c-222 -64 -392 -238 -449 -458 -21 -80 -21 -246 0 -327 43 -167 168 -325 320 -404 153 -79 -244 -72 4144 -72 4388 0 3991 -7 4144 72 109 57 207 155 263 263 55 107 73 181 73 305 0 124 -18 198 -73 305 -56 108 -154 206 -262 262 -156 80 262 73 -4151 72 -3726 0 -3952 -1 -4009 -18z" />
      <path d="M1105 3181 c-222 -64 -392 -238 -449 -458 -21 -80 -21 -246 0 -327 43 -167 168 -325 320 -404 153 -79 -244 -72 4144 -72 4388 0 3991 -7 4144 72 109 57 207 155 263 263 55 107 73 181 73 305 0 124 -18 198 -73 305 -56 108 -154 206 -262 262 -156 80 262 73 -4151 72 -3726 0 -3952 -1 -4009 -18z" />
   </g>
</svg>

AppIcons.close = () => <svg className="prefix__cls-1" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 920.000000 960.000000" preserveAspectRatio="xMidYMid meet">
   <g transform="translate(0.000000,960.000000) scale(0.100000,-0.100000)" fill="#000000" className="prefix__cls-1 close" stroke="none">
      <path d="M1345 9185 c-109 -18 -176 -40 -270 -88 -65 -33 -102 -63 -201 -162 -136 -135 -178 -200 -225 -345 -41 -127 -54 -251 -39 -369 24 -189 74 -306 191 -441 46 -52 445 -459 889 -905 1392 -1399 1655 -1666 1655 -1675 0 -9 -177 -188 -1630 -1650 -876 -881 -985 -996 -1027 -1086 -38 -80 -77 -237 -84 -338 -12 -158 53 -398 139 -518 55 -77 224 -242 293 -285 63 -41 232 -96 342 -113 101 -15 198 -8 329 25 151 39 203 68 338 191 134 122 777 756 1838 1814 389 388 711 705 716 705 9 0 465 -450 1696 -1674 850 -845 952 -942 1045 -984 84 -38 230 -75 330 -83 154 -13 402 53 522 139 74 53 230 211 278 281 43 64 93 207 115 329 21 119 16 211 -20 353 -39 152 -68 204 -191 339 -122 134 -714 734 -1794 1818 -399 400 -725 732 -725 737 0 9 134 144 1745 1765 426 429 802 812 834 852 90 112 151 281 162 453 11 163 -52 401 -139 522 -53 74 -211 230 -281 278 -111 75 -363 138 -506 126 -100 -8 -256 -47 -334 -84 -90 -42 -205 -151 -1086 -1027 -1466 -1457 -1641 -1630 -1650 -1630 -5 0 -330 320 -722 710 -1200 1196 -1759 1747 -1858 1833 -130 114 -233 161 -410 187 -112 18 -158 17
-265 0z"/>
   </g>
</svg>


AppIcons.Camera = () => (
   <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.000000 400.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" className="prefix__cls-1" stroke="none">
         <path d="M1516 3601 c-50 -17 -136 -91 -136 -117 0 -8 -4 -14 -10 -14 -5 0
-12 -11 -16 -25 -3 -14 -10 -25 -15 -25 -5 0 -9 -6 -9 -14 0 -8 -6 -21 -12
-28 -7 -8 -21 -29 -31 -48 -34 -64 -52 -95 -57 -100 -3 -3 -12 -18 -20 -33
l-15 -29 -128 -1 -129 -2 -5 27 c-6 30 -66 98 -87 98 -8 0 -16 5 -18 11 -5 14
-418 14 -427 1 -3 -5 -16 -14 -29 -21 -31 -16 -72 -85 -72 -120 0 -24 -7 -33
-41 -50 -51 -27 -60 -34 -128 -102 -60 -60 -81 -84 -81 -95 0 -3 -6 -20 -14
-37 -36 -80 -36 -93 -36 -953 0 -821 5 -992 31 -1018 5 -6 9 -17 9 -25 1 -9 9
-34 20 -56 11 -22 19 -46 20 -52 0 -7 4 -13 8 -13 5 0 19 -19 32 -42 50 -89
230 -245 305 -264 14 -3 25 -10 25 -14 0 -4 17 -11 37 -15 21 -4 41 -12 45
-18 10 -17 2909 -17 2929 0 8 6 31 14 52 18 20 4 37 11 37 15 0 5 14 11 30 15
17 4 30 10 30 15 0 4 8 10 18 14 68 22 223 176 269 266 10 19 21 36 25 38 4 2
10 19 14 38 3 19 10 34 14 34 5 0 11 19 15 43 4 23 12 48 19 56 16 20 16 1602
0 1622 -7 8 -15 33 -19 57 -4 23 -10 42 -15 42 -4 0 -11 12 -14 26 -22 88
-201 292 -303 344 -26 14 -50 27 -53 31 -3 3 -27 13 -55 23 -27 10 -59 22 -70
27 -28 12 -165 31 -266 36 l-87 4 -18 37 c-11 20 -21 39 -24 42 -5 5 -14 21
-67 120 -84 154 -138 215 -209 232 -34 8 -223 12 -634 14 -525 2 -590 0 -634
-15z m-786 -584 c63 -38 95 -97 95 -177 0 -53 -24 -121 -49 -145 -42 -37 -58
-46 -107 -55 -80 -15 -149 2 -199 49 -22 21 -40 45 -40 54 0 10 -4 17 -10 17
-6 0 -10 29 -10 68 0 149 89 230 235 215 28 -3 66 -15 85 -26z m1590 -46 c0
-4 28 -11 62 -15 34 -3 67 -11 72 -16 6 -6 18 -10 29 -10 16 0 64 -18 137 -53
33 -15 124 -69 130 -77 3 -3 21 -16 41 -28 37 -23 219 -206 219 -221 0 -4 9
-18 20 -31 11 -13 20 -29 20 -37 0 -7 4 -13 10 -13 5 0 12 -11 16 -25 3 -14
10 -25 15 -25 5 0 9 -4 9 -9 0 -5 14 -39 30 -74 17 -36 30 -72 30 -81 0 -8 4
-17 10 -21 5 -3 12 -33 16 -66 4 -32 11 -62 15 -65 12 -7 12 -351 0 -358 -4
-3 -11 -31 -14 -62 -3 -31 -11 -62 -16 -67 -6 -6 -11 -20 -11 -30 0 -10 -7
-35 -16 -55 -28 -63 -49 -107 -54 -112 -3 -3 -14 -23 -25 -45 -21 -40 -114
-162 -156 -203 -80 -76 -240 -192 -266 -192 -7 0 -13 -4 -13 -10 0 -5 -16 -12
-35 -16 -19 -3 -35 -10 -35 -14 0 -5 -18 -11 -40 -15 -22 -4 -40 -10 -40 -15
0 -4 -24 -11 -52 -15 -29 -4 -57 -11 -63 -15 -36 -28 -395 -27 -430 0 -5 5
-36 11 -67 15 -32 4 -58 11 -58 16 0 5 -8 9 -17 9 -30 0 -225 102 -261 137 -7
7 -18 13 -23 13 -12 0 -209 196 -209 208 0 5 -10 21 -23 35 -12 15 -33 48 -47
74 -14 26 -28 49 -32 51 -5 2 -8 9 -8 15 -1 7 -9 30 -20 52 -11 22 -19 46 -20
53 0 7 -4 20 -9 30 -26 51 -54 217 -54 322 -1 72 23 264 33 274 6 6 10 20 10
32 0 13 4 25 9 28 5 4 12 24 16 46 4 22 10 40 15 40 4 0 11 16 14 35 4 19 11
35 16 35 6 0 10 5 10 11 0 13 62 117 93 156 34 42 180 186 206 203 12 8 32 23
43 33 12 9 27 17 35 17 7 0 13 4 13 8 0 5 20 17 45 28 25 10 45 21 45 26 0 4
16 10 35 14 19 3 35 10 35 15 0 4 20 11 45 15 25 3 45 10 45 14 0 5 29 12 65
15 36 4 65 11 65 16 0 5 77 9 170 9 94 0 170 -4 170 -9z"/>
         <path d="M2010 2661 c0 -4 -20 -11 -45 -14 -24 -4 -53 -11 -62 -16 -10 -5 -47
-23 -83 -41 -56 -28 -97 -54 -148 -95 -28 -23 -98 -94 -112 -115 -8 -12 -23
-31 -32 -42 -10 -11 -23 -33 -30 -49 -7 -16 -16 -29 -20 -29 -4 0 -10 -16 -14
-35 -3 -19 -10 -35 -14 -35 -5 0 -11 -19 -15 -42 -4 -24 -12 -49 -18 -57 -16
-19 -16 -313 0 -332 6 -8 14 -35 18 -61 4 -27 11 -48 15 -48 4 0 11 -16 14
-35 4 -19 11 -35 15 -35 5 0 11 -8 15 -17 5 -17 15 -34 41 -71 12 -17 165
-171 188 -189 9 -7 21 -13 26 -13 5 0 16 -6 23 -12 21 -21 120 -67 156 -74 18
-4 35 -11 38 -15 7 -12 374 -12 374 0 0 5 19 11 42 15 23 4 45 10 48 14 3 4
34 22 69 40 35 18 69 39 76 45 7 7 23 19 35 27 26 17 76 65 105 101 57 69 85
108 85 118 0 6 4 11 9 11 5 0 12 13 16 30 4 17 11 30 16 30 5 0 9 8 9 18 0 9
7 30 15 46 8 15 15 39 15 52 0 12 5 26 10 29 6 4 10 67 10 155 0 88 -4 151
-10 155 -5 3 -10 17 -10 30 0 13 -4 26 -9 29 -5 4 -12 24 -16 46 -4 22 -11 40
-15 40 -5 0 -11 14 -15 30 -4 17 -11 30 -16 30 -5 0 -9 6 -9 13 0 8 -11 27
-25 43 -14 16 -25 32 -25 37 0 9 -151 157 -161 157 -4 0 -14 6 -21 13 -7 6
-44 27 -83 45 -38 19 -78 38 -87 43 -9 5 -35 12 -58 15 -22 4 -43 11 -46 15
-7 12 -284 12 -284 0z"/>
      </g>
   </svg>
)


export default AppIcons;