import React from 'react'
import MaterialTable from 'material-table'
import { AppCard, Modal, NoticeCard, PageLoader, TableMaterialIcon } from '../Components'
import helpers from '../../js/functions/helpers'
import { EditSharp, Settings, Delete, } from '@material-ui/icons'
import ReducerAction from '../../js/Reducer_actions'
import appFunction from '../../js/functions/appFunction'



const ModalScreen = {
   addNewSkill: "add_new_skill",
   viewSkillData: "view_skill_data",
   deleteSkill: "delete_skill_warning"
}



const Preview = ({ data, indicator, setCState }) => (
   <div className="details-da" style={{ minWidth: 700, minHeight: 500 }}>
      {data?.skill &&
         <React.Fragment>
            <h6 className="text-capitalize">{data?.skill}</h6>
            <MaterialTable
               columns={[
                  {
                     title: "Skill", field: "skill",
                     cellStyle: { textTransform: 'capitalize' }
                  },
                  { title: "Workers", field: "workers" },
                  { title: "Total Request", field: "requests" },
                  {
                     title: "Action",
                     // field: "request",
                     render: () => (<div>
                        <span onClick={() => setCState(d => ({
                           ...d, modal: ModalScreen.addNewSkill,
                           data: data
                        }))} className="d-inline-block cursor-pointer mr-4">
                           <EditSharp />
                        </span>
                        <span onClick={() => setCState(d => ({
                           ...d, modal: ModalScreen.deleteSkill,
                           data: data
                        }))} >
                           <Delete />
                        </span>
                     </div>)
                  }
               ]}
               data={[data]}
               options={{ search: false, showTitle: false, toolbar: false, paging: false }}
            />
         </React.Fragment>
      }
      {indicator === ReducerAction.indicator.getSkillInfo &&
         <div style={{ position: 'relative', width: '100%', top: 0, marginTop: '40%', left: 0 }} >
            <PageLoader />
         </div>
      }
   </div>
)


const AddEditSkill = ({ editData, setCState }) => (
   <div className="card _shadow" style={{ width: 450 }}>
      <div className="card-body d-block">
         <strong>{editData?.skill ? 'Edit Skill' : 'Add New Skill'}</strong>
         <input style={{ margin: '1rem 0' }} id="skill_name" placeholder="Name"
            className="form-control mb-1" defaultValue={editData?.skill ?? ''} />
         <input list="skill-category" id="skill_category" placeholder="Category" autoComplete="on"
            className="form-control mb-1" defaultValue={editData?.category ?? ''} />
         <datalist id="skill-category">
            <option value="Technical And Industrial Services" />
            <option value="Educational And Creative Services" />
            <option value="Home Services" />
            <option value="Fashion And Lifestyle Services" />
            <option value="Health And Wellness Services" />
            <option value="Logistics And Other Professionals" />
         </datalist>
         <div className="mt-2">
            <button className="btn btn-primary mt-4 mr-2" onClick={w => {
               let skillName = document.querySelector('#skill_name').value
               let category = document.querySelector('#skill_category').value
               appFunction.addEditSkill(skillName, category, editData?.skill, res => {
                  setCState(d => ({ ...d, modal: false }))
               })
            }}>Submit</button>
         </div>
      </div>
   </div>
)



export default ({ data, indicator }) => {
   React.useEffect(() => {
      appFunction.getSkills()
   }, [])
   const [cState, setCState] = React.useState({ modal: '', data: null })
   return (
      <AppCard>
         <MaterialTable
            isLoading={data?.type !== ReducerAction.screen.settingSkill}
            icons={TableMaterialIcon}
            actions={[
               {
                  icon: 'add',
                  tooltip: 'Add User',
                  isFreeAction: true,
                  onClick: () => setCState(d => ({ ...d, data: null, modal: ModalScreen.addNewSkill }))
               }
            ]}
            columns={[
               {
                  title: 'Name',
                  field: 'name',
                  headerStyle: { fontWeight: 'bold' },
                  cellStyle: { textTransform: 'capitalize' }

               },
               {
                  title: 'Category',
                  field: 'category',
                  headerStyle: { fontWeight: 'bold' },
                  cellStyle: { textTransform: 'capitalize' }

               },
               {
                  title: "Action",
                  headerStyle: { fontWeight: 'bold' },
                  // field: "updatedAt",
                  render: e => <div className="cursor-pointer" onClick={() => {
                     appFunction.getSkillInfo(e.name, true, e.category)
                     setCState(d => ({ ...d, modal: ModalScreen.viewSkillData }))
                  }}> <Settings /></div>
               }
            ]}
            options={{
               pageSize: 10,
               pageSizeOptions: [10, 20, 30, 40, 50]
            }}
            data={data?.data?.data instanceof Array ? data?.data?.data : []}
            onSelectionChange={e => console.log(e)}
            title="Skills & Categories" />

         {cState.modal?.length > 0 &&
            <Modal onOverlayClick={() => setCState(d => ({ ...d, modal: false }))}>
               {cState.modal === ModalScreen.addNewSkill ?
                  <AddEditSkill editData={cState.data} setCState={setCState} /> :
                  cState.modal === ModalScreen.deleteSkill ?
                     <NoticeCard text={`Are you sure you want to delete ${cState?.data?.skill}?`}
                        onYesClick={() => {
                           appFunction.deleteRecord(`${helpers.url.deleteSkill}?skill=${cState.data?.skill}`, "GET", null, appFunction.getSkills)
                           setCState(d => ({ ...d, modal: false }))
                        }}
                        onNoClick={() => setCState(d => ({ ...d, modal: false }))} /> :
                     <Preview data={data?.data1} indicator={indicator} setCState={setCState} />
               }
            </Modal>
         }
      </AppCard>
   )
}
