import ReducerAction from './Reducer_actions';
export default (state, action) => {
   switch (action.type) {
      case ReducerAction.loader:
         return {
            ...state,
            loader: action.payload,
            error: null,
            flash: null
         }
      case ReducerAction.flash:
         return {
            ...state,
            flash: action.payload,
            error: null,
            loader: null
         }
      case ReducerAction.allJobsLoaded:
         return {
            ...state,
            jobs: action.payload
         }
      case ReducerAction.singleUserLoaded:
         return {
            ...state,
            userData: action.payload
         }
      case ReducerAction.complaintLoaded:
         return {
            ...state,
            complaint: action.payload
         }
      case ReducerAction.walletLoaded:
         return {
            ...state,
            wallet: action.payload
         }
      case ReducerAction.epaymentLoaded:
         return {
            ...state,
            epayment: action.payload
         }
      case ReducerAction.dUsersLoaded:
         return {
            ...state,
            dUsers: action.payload
         }
      case ReducerAction.updateState:
         return {
            ...state,
            cUpdate: Math.random()
         }
      case ReducerAction.usersLoaded:
         return {
            ...state,
            users: action.payload,
            displayUsers: action.payload
         }
      case ReducerAction.error:
         return {
            ...state,
            flash: null,
            loader: null,
            error: action.payload
         }
      case ReducerAction.DEFAULT_PASSWORD:
         return {
            ...state,
            defaultPWD: action.payload
         }
      case ReducerAction.isAuthenticated:
         return {
            ...state,
            auth: true
         }
      case ReducerAction.modal.run:
         return {
            ...state,
            modal: action.payload,
            modalView: action.view ? action.view : null
         }
      default:
         return state
   }

}


