import React, { useContext } from 'react';
import Logo from '../images/logo.png'
import AppContext from '../js/Context';
import appFunction from '../js/functions/appFunction';
import History from '../js/History';
// import ChangePassword from './ChangePassword';
import { PageLoader, NotFound } from './Components';

export const RunAuthentication = () => <div className="run_auth"><div> <NotFound text="Authenticating..." /><PageLoader /></div></div>

export const Login = () => (
   <div className="login-section">
      <div className="site-logo text-center mb-3">
         <img src={Logo} alt="Logo" style={{ maxWidth: '120px' }} className="mb-3" />
      </div>
      <div className="card _shadow">
         <div className="card-body">
            <h3>Sign In </h3>
            <form onSubmit={appFunction.processLogin}>
               <div className="text-center text-red" id="error"></div>
               <label htmlFor="usr" className="font-bold">Username</label>
               <input type="text" id="usr" name="email" className="form-control mb-1" />
               <label htmlFor="pwd" className="font-bold">Password</label>
               <input type="password" id="pwd" name="pwd" className="form-control" />
               <button type="submit" className="btn btn-primary" id="submit_btn">Sign In</button>
            </form>
         </div>
      </div>
      <div className="dfooter">
         Holla || hollajobs.com © 2020 Alright Reserved
      </div>
   </div>
)

export const OTP = () => {
   let appState = React.useContext(AppContext).state
   React.useEffect(w => {
      if (!appState?.account?.email) {
         History.push('/')
      }
   }, [])
   return (
      <div className="login-section">
         <div className="site-logo text-center mb-3">
            <img src={Logo} alt="Logo" style={{ maxWidth: '120px' }} className="mb-3" />
         </div>
         <div className="card _shadow">
            <div className="card-body">
               <div className="text-center">
                  <strong style={{ fontSize: 23 }}>Enter OTP</strong>
                  <div style={{ fontSize: 12, lineHeight: .8, color: '#4d4d4d' }}>sent to</div>
                  <span className="text-blue" style={{ fontSize: 12, lineHeight: .5 }}>{appState?.account?.email}</span>
               </div>
               <form onSubmit={appFunction.submitOTP} style={{ marginTop: '1rem' }}>
                  <div className="text-center text-red" id="error"></div>
                  <input type="number" min={0} name="otp" className="form-control mb-1" placeholder="Enter OTP" maxLength={4} />
                  <button type="submit" className="btn btn-primary" id="submit_btn">SUBMIT</button>
               </form>
            </div>
         </div>
         <div className="dfooter">
            Holla || hollajobs.com © 2020 Alright Reserved
   </div>
      </div>
   )
}

export const ChangePassword = () => {
   let appState = React.useContext(AppContext).state

   React.useEffect(w => {
      if (!appState?.account?.email) {
         History.push('/')
      }
   }, [])
   return (
      <div className="login-section">
         <div className="site-logo text-center mb-3">
            <img src={Logo} alt="Logo" style={{ maxWidth: '120px' }} className="mb-3" />
         </div>
         <div className="card _shadow">
            <div className="card-body">
               <div className="text-center">
                  <strong style={{ fontSize: 23 }}>Enter your password</strong>
                  <div style={{ fontSize: 12, lineHeight: .8, color: '#4d4d4d' }}>for</div>
                  <span className="text-blue" style={{ fontSize: 12, lineHeight: .5 }}>{appState?.account?.email}</span>
               </div>
               <form onSubmit={appFunction.submitPassword} style={{ marginTop: '1rem' }}>
                  <div className="text-center text-red" id="error">{appState.error}</div>
                  <input name="pwd" className="form-control mb-1" placeholder="Password" />
                  <input name="c_pwd" className="form-control mb-1" placeholder="Confirm Password" />
                  <button type="submit" className="btn btn-primary" id="submit_btn">SUBMIT</button>
               </form>
            </div>
         </div>
         <div className="dfooter">
            Holla || hollajobs.com © 2020 Alright Reserved
</div>
      </div>
   )
}
// export default () => {
//    const appState = useContext(AppContext).state
//    return <>{appState.defaultPWD ? <ChangePassword appState={appState} /> : <Login />}</>
// }