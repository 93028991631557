import React, { useContext } from 'react';
import * as AppComponents from '../Components';
import AppIcons from '../icons';
import AppContext from '../../js/Context';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import NoPhoto from '../../images/no-photo.png';

import appFunction from '../../js/functions/appFunction';


export const AddNewUser = () => (
   <AppComponents.AppCard>
      <form data-status="create" onSubmit={appFunction.createDashBoardUser} id="add_user_form">
         <div className="row">
            <div className="col-md-4">
               <h6 className="c-title">Name*</h6>
               <input name="name" className="form-control" required />
            </div>
            <div className="col-md-4">
               <h6 className="c-title">Email*</h6>
               <input type="email" name="email" className="form-control" required />
            </div>
            <div className="col-md-4">
               <h6 className="c-title">Phone</h6>
               <input type="tel" name="phone" className="form-control" />
            </div>
            <div className="col-md-4">
               <h6 className="c-title">User Type</h6>
               <select name="user_type" className="form-control">
                  <option value="">Select</option>
                  <option value="admin">Admin</option>
                  <option value="support">Support</option>
                  <option value="supervisor">Supervisor</option>
                  <option value="sponsor">Sponsor</option>
               </select>

               {/* <div className="mt-2">
                     <label className="d-block">
                        <input type="radio" name="user_type" className="form-control accc d-none" value="admin" />
                        <span></span>
                        <em> Admin</em>
                     </label>
                     <label className="d-block">
                        <input type="radio" name="user_type" className="form-control accc d-none" value="support" />
                        <span></span>
                        <em> Support</em>
                     </label>
                     <label className="d-block">
                        <input type="radio" name="user_type" className="form-control accc d-none" value="supervisor" />
                        <span></span>
                        <em> Supervisor</em>
                     </label>
                     <label className="d-block">
                        <input type="checkbox" name="access_social" className="form-control accc d-none" defaultChecked={userData ? userData.access.social === "1" : false} />
                        <span></span>
                        <em> Social</em>
                     </label>
                  </div> */}
            </div>

            <div className="col-md-12 mt-3">
               <button type="submit" className="btn btn-orange mr-3">Add</button>
               <button type="reset" className="btn btn-secondary">Reset</button>
            </div>
         </div>
      </form>
   </AppComponents.AppCard>
)




const DataPreview = (props) => (
   <section className="usd-prev">
      <div className="usd-title">
         <h6>{props.title}</h6>
         <div onClick={helpers.togglePreviewCard}><i className="fa fa-chevron-up text-gray"></i> </div>
      </div>
      <div className="d-none">{props.children}</div>
   </section>
)


const UserTypeSelection = ({ userData }) => (
   <div className="px-4 py-2">
      <label className="d-block">
         <input type="checkbox" defaultChecked={userData?.user_type?.indexOf('admin') > -1} name="user_type" className="form-control accc d-none" value="admin" onChange={w => { if (w.target.checked) { appFunction.setUserType(userData?.email, 'admin') } }} />
         <span></span>
         <em> Admin</em>
      </label>
      <label className="d-block">
         <input type="checkbox" defaultChecked={userData?.user_type?.indexOf('support') > -1} name="user_type" className="form-control accc d-none" value="support" onChange={w => { if (w.target.checked) { appFunction.setUserType(userData?.email, 'support') } }} />
         <span></span>
         <em> Support</em>
      </label>
      <label className="d-block">
         <input type="checkbox" defaultChecked={userData?.user_type?.indexOf('supervisor') > -1} name="user_type" className="form-control accc d-none" value="supervisor" onChange={w => { if (w.target.checked) { appFunction.setUserType(userData?.email, 'supervisor') } }} />
         <span></span>
         <em> Supervisor</em>
      </label>

   </div>
)


const SkillSearch = () => (
   <div className="d-flex align-items-center pl-4 border-bottom">
      <i className="fa fa-search text-gray"></i>
      <form autoComplete="off" onSubmit={appFunction.setTypingFilter} data-filter={ReducerAction.filters.addExistingUser} className="w-100">
         <input type="text" name="search" className="filter-control border-0" placeholder="Email or Phone" />
      </form>
   </div>
)

const AllUsersRows = ({ e }) => (
   <tr onClick={txt => { }}>
      <td className="nm_sexxtion">
         <div className="newbies_avatar"> <img src={NoPhoto} alt={e.email} /> </div>
         <div className="position-relative">
            <h6 className="user_name" data-email={e.email}>{e.name}</h6>
            <small className="font-bold">
               <div>{e.email}</div>
               <span>{e.phone}</span>
            </small>
         </div>
      </td>
   </tr>
)

export const AddExistingUsers = () => {
   let appState = useContext(AppContext).state
   let userData = appState.dData
   return (
      <div className="row">
         <div className="col-md-6">
            <AppComponents.AppCard title={<SkillSearch />} bodyStyle={{ minHeight: 500, padding: 0 }}>
               {userData.type === ReducerAction.filters.addExistingUser ? userData.data instanceof Array ?
                  <div className="table-data">
                     <table>
                        <tbody>
                           {userData.data.map((e, i) => <AllUsersRows key={i} e={e} />)}
                        </tbody>
                     </table>
                  </div> :
                  <AppComponents.NotFound text={userData.data} /> :
                  <AppComponents.NotFound text="Search a User" />
               }
               {appState.indicator === ReducerAction.indicator.searchQuery &&
                  <div style={{ position: 'absolute', width: '100%', top: 0, marginTop: '50px' }} >
                     <AppComponents.PageLoader />
                  </div>
               }

            </AppComponents.AppCard>
         </div>
         <div className="col-md-6">
            <AppComponents.AppCard bodyStyle={{ minHeight: 540, padding: 0 }}>
               {userData.type === ReducerAction.filters.addExistingUser ? userData.data instanceof Array ?
                  <DataPreview title="User Types">
                     <UserTypeSelection userData={userData.data[0]} />
                  </DataPreview> :
                  <AppComponents.NotFound text="click a name to assign" /> :
                  <AppComponents.NotFound text="click a name to assign" />
               }
            </AppComponents.AppCard>
         </div>
      </div>
   )
}


