import React, { useContext } from 'react';
import { ScreenTabs, ScreenTabsLink } from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import AppJobs from './JobsApp';
import USSDJobs from './JobsUSSD';


const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Jobs Management</h5>
      <ScreenTabs>
         <ScreenTabsLink name="App Jobs" onClick={e => helpers.switchScreenView(undefined)} active={defaultScreen} />
         <ScreenTabsLink name="USSD Jobs" onClick={e => helpers.switchScreenView(ReducerAction.screen.jobsUSSD)} active={screen === ReducerAction.screen.jobsUSSD} />
      </ScreenTabs>
   </div>
)

const RunScreen = (appState) => {
   switch (appState?.modal?.screen) {
      case ReducerAction.screen.jobsUSSD:
         return <USSDJobs data={appState.jobs.ussd} />
      default:
         return <AppJobs data={appState.jobs.app} />
   }
}

export default () => {
   let appState = useContext(AppContext).state
   // console.log(appState?.dData)
   return (
      <Layout bname="Jobs Management" bfa="user" sActive={2}>
         <NavTab screen={appState?.modal?.screen} defaultScreen={!appState?.modal?.screen} />
         {RunScreen(appState)}
      </Layout>
   )
}