import React, { useContext } from 'react';
import MaterialTable from 'material-table'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { AppCard, Modal, PageLoader, TableMaterialIcon } from '../Components';
import appFunction from '../../js/functions/appFunction';
import helpers from '../../js/functions/helpers';
import { Settings } from '@material-ui/icons'
import ReducerAction from '../../js/Reducer_actions';


const JobRequestSearch = () => {
   const [cState, setCState] = React.useState({
      dateModal: null,
      selectionRange: {
         startDate: new Date(),
         endDate: new Date(),
         show: null
      }
   })
   //for closing the date picker
   const closeDatePicker = (e) => {
      if (e === 'close') {
         cState.selectionRange.show = null
      } else {
         cState.selectionRange.show = true
      }
      setCState(prev => ({ ...prev, dateModal: null }))
   }
   const setSelectedDate = ({ range1 }) => {
      setCState(prev => ({ ...prev, selectionRange: { startDate: range1.startDate, endDate: range1.endDate } }))
   }

   return (
      <div>
         <div className="d-flex  align-items-center">
            <i className="fa fa-search text-gray job-srch-icon"></i>
            <form onSubmit={appFunction.setTypingFilter} data-filter={ReducerAction.filters.jobRequests} className="w-100">
               <input type="text" name="search" style={{ maxWidth: 300 }} className="filter-control pl-5" placeholder="Job ID, Job Title, Worker or Client Name" />
            </form>
            <select id="select_status" className="filter-control" style={{ maxWidth: '150px' }} data-filter={ReducerAction.filters.jobRequests} onChange={appFunction.setSelectFilter}>
               <option value="">Job Status</option>
               <option value="pending">Pending</option>
               <option value="completed">Completed</option>
               <option value="cancel">Canceled</option>
            </select>
            {/* <select id="select_date" onClick={w => setCState(prev => ({ ...prev, dateModal: true }))} className="filter-control" style={{ maxWidth: '230px' }}>
               {!cState.selectionRange.show ? <option value="">Select Date</option> :
                  <option>{`${cState.selectionRange.startDate?.toLocaleDateString()} - ${cState.selectionRange.endDate?.toLocaleDateString()}`}</option>
               }
            </select> */}
            <input type="date" id="select_date" className="filter-control" style={{ maxWidth: '160px' }} placeholder="Start Date" />
         </div>
         <div style={{ position: 'relative' }}>
            {/* <div className="date-picker-parent" style={{ display: cState.dateModal ? 'block' : 'none' }}>
               <DateRangePicker ranges={[cState.selectionRange]} onChange={setSelectedDate} />
               <div className="p-3">
                  <button className="btn btn-danger btn-sm mr-3" onClick={w => closeDatePicker('close')}>Cancel</button>
                  <button className="btn btn-primary btn-sm" onClick={w => closeDatePicker('done')}>Done</button>
               </div>
            </div> */}
         </div>
      </div>
   )
}

// For detail preview
const PaymentDataTable = ({ data }) => (
   <MaterialTable
      style={{ minWidth: 750, marginTop: 1 }}
      data={data ? [data] : []}
      title="Payment"
      columns={[
         {
            field: 'method',
            title: "Method",
            headerStyle: { fontWeight: 'bold' },
            // render: () => data?.method,
            cellStyle: { textTransform: 'capitalize' }
         },
         {
            field: 'material',
            title: "Material",
            render: () => helpers.formatMoney(data?.material),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'labour',
            title: "Labour",
            render: () => helpers.formatMoney(data?.labour),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'transport',
            title: "Transport",
            render: () => helpers.formatMoney(data?.transport),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            title: 'Service Charge',
            field: "service_charge",
            render: () => helpers.formatMoney(data?.service_charge),
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'total',
            title: "Total",
            render: () => helpers.formatMoney(data?.total),
            headerStyle: { fontWeight: 'bold' },
         },
      ]}
      options={{ search: false, paging: false }}
   />
)

const UserDataTable = ({ data, title, clientID }) => (
   <MaterialTable
      title={title}
      style={{ minWidth: 750, marginTop: 10 }}
      data={data ?? []}
      columns={[
         {
            title: 'User Type',
            field: 'user_type',
            cellStyle: { fontWeight: 'bold', textTransform: 'capitalize' },
            render: rowData => String(rowData?._id) === String(clientID) ? "Client" : "Worker",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'name',
            title: "Name",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'phone',
            title: "Phone",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'email',
            title: "Email",
            headerStyle: { fontWeight: 'bold' },
         },
         {
            field: 'location.address',
            title: "Address",
            headerStyle: { fontWeight: 'bold' },
         }
      ]}
      options={{ search: false, paging: false }}
   />
   // console.log(data)
)



export default ({ data, indicator }) => {

   React.useEffect(() => {
      appFunction.getAllJobsApp()
   }, [])
   const [cState, setCState] = React.useState({ modal: false, data: [] })

   let tableSetting = {
      headerStyle: { fontWeight: 'bold' },
      cellStyle: { textTransform: 'capitalize' }
   }
   let dataLen = data?.length
   return (
      <AppCard>
         <MaterialTable
            isLoading={data === null}
            icons={TableMaterialIcon}
            components={{
               Toolbar: props => (<JobRequestSearch />)
            }}
            columns={[
               {
                  title: 'Job Title',
                  field: 'title',
                  ...tableSetting
               },
               {
                  title: 'Job Location',
                  field: 'location.address',
                  headerStyle: { fontWeight: 'bold' },
               },
               {
                  title: 'Job Status',
                  field: 'phone',
                  cellStyle: { minWidth: 250 },
                  render: e => (
                     <div className="pymt_status">
                        <i style={{ textTransform: 'capitalize' }} className={e.status === 10 ? 'f' :
                           e.status === 7 ? 'c' : 'p'}> {helpers.runJobStage(e?.status)}</i>
                     </div>),
                  ...tableSetting

               },
               {
                  title: 'Date',
                  field: 'createdAt',
                  render: e => helpers.calculatePostDate(e.createdAt),
                  headerStyle: { textTransform: 'capitalize' }
               },
               {
                  title: "Action",
                  headerStyle: { fontWeight: 'bold', },
                  render: e => <div className="cursor-pointer" onClick={() => {
                     setCState(d => ({ ...d, modal: true, data: e }))
                  }}> <Settings /></div>
               }
            ]}
            options={{
               pageSize: 10,
               pageSizeOptions: [10, 20, 30, 40, 50]
            }}
            data={data instanceof Array ? data : []}
            onSelectionChange={e => console.log(e)}
            onChangePage={(page, pageSize) => {
               //check the page and the page size
               if ((pageSize * page) >= (dataLen - pageSize)) {
                  //get page numba
                  let pageN = Math.ceil(dataLen / 25)
                  if (pageN > 1) {
                     appFunction.getAllJobsApp(undefined, undefined, undefined, undefined, true, pageN)
                  }
               }
            }}
            title="Jobs App" />
         {cState.modal &&
            <Modal onOverlayClick={() => setCState(d => ({ ...d, modal: false }))}
               modalContainerStyle={{ backgroundColor: "#fff" }}
               modalContentStyle={{ alignItems: "flex-start" }}
            >
               <PaymentDataTable data={cState?.data?.payment} />
               <UserDataTable data={cState?.data?.users} clientID={cState?.data?.client_id} title="Users" />
            </Modal>
         }

      </AppCard>
   )
}
