import React, { useContext } from 'react';
import * as AppComponents from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';
import appFunction from '../../js/functions/appFunction';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import { AddNewUser, AddExistingUsers } from './AddUsers';

const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Add User</h5>
      <AppComponents.ScreenTabs>
         <AppComponents.ScreenTabsLink name="New User" active={defaultScreen} onClick={txt => helpers.switchScreenView()} />
         <AppComponents.ScreenTabsLink name="Existing User" onClick={txt => helpers.switchScreenView(ReducerAction.screen.addExistingUser)} active={screen === ReducerAction.screen.addExistingUser} />
         {/* <AppComponents.ScreenTabsLink name="Users Activities" onClick={txt => helpers.switchScreenView(ReducerAction.screen.consoleUserActivities)} active={screen === ReducerAction.screen.consoleUserActivities} /> */}
      </AppComponents.ScreenTabs>
   </div>
)

export default () => {
   const appState = useContext(AppContext).state
   let screen = appState.modal.screen
   let defaultScreen = [ReducerAction.screen.addExistingUser].indexOf(screen) === -1
   React.useEffect(e => {
      // appFunction.getAllDashboardUsers()
   }, [])
   return (
      <Layout bname="Add User" sActive={6}>
         {['superadmin', 'admin'].indexOf(appState?.account?.user_type_single) === -1 ?
            <AppComponents.NotFound text="Unauthorized" claz="bg-white" /> : <>
               <NavTab screen={screen} defaultScreen={defaultScreen} />
               {screen === ReducerAction.screen.addExistingUser ?
                  <AddExistingUsers /> : <AddNewUser />
               }
            </>}
      </Layout>
   )
}