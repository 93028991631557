import React, { useContext } from 'react';
import { ScreenTabs, ScreenTabsLink } from '../Components'
import Layout from '../Layout';
import AppContext from '../../js/Context';
import ReducerAction from '../../js/Reducer_actions';
import helpers from '../../js/functions/helpers';
import HollaSkill from './HollaSkill';


const NavTab = ({ screen, defaultScreen }) => (
   <div className="my-4">
      <h5 className="d-inline-block" style={{ marginRight: 50, marginBottom: 0, verticalAlign: 'bottom' }}>Skills Management</h5>
      <ScreenTabs>
         <ScreenTabsLink name="Skills" active={true} onClick={e => helpers.switchScreenView()} />
      </ScreenTabs>
   </div>
)
const RunScreen = (appState) => {
   switch (appState?.modal?.screen) {
      default:
         return <HollaSkill screen={appState.modal?.screen} data={appState?.dData} indicator={appState.indicator} />
   }
}

export default () => {
   let appState = useContext(AppContext).state
   // console.log(appState?.dData)
   return (
      <Layout bname="Skill Management" bfa="user" sActive={3}>
         <NavTab screen={appState?.modal?.screen} />
         {RunScreen(appState)}
      </Layout>
   )
}