import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.png';
import Loader from '../images/loader.gif';
import NoPhoto from '../images/no-photo.png'
import AppIcons from './icons';
import History from '../js/History';
import helpers from '../js/functions/helpers';
import AppContext from '../js/Context';
import appFunction from '../js/functions/appFunction';
import {
   AddBox, Check, Clear, DeleteOutline, SkipNextRounded,
   SkipPreviousRounded,
   ChevronRight, Edit, SaveAlt, EditSharp,
   FilterList, FirstPage, Settings, Delete,
   LastPage, Search,
   ArrowDownward, Remove, ViewColumn
} from '@material-ui/icons'



export var ProfilePhoto;
export const Header = () => {
   const appState = useContext(AppContext).state
   let userData = appState.account
   let prfIMg = userData.avatar
   ProfilePhoto = prfIMg ? helpers.url.profileImage + prfIMg : NoPhoto
   return (
      <nav>
         <div className="text-right">
            <div className="profil">
               <div>
                  <img src={ProfilePhoto} alt="Profile" />
               </div>
               <div>
                  <Link to="/admin/profile" style={{ textDecoration: 'none', textAlign: 'left', color: 'inherit' }}>
                     <h6 className="mb-0">{userData?.name}</h6>
                     <small className="text-capitalize">{userData?.user_type_single}</small>
                  </Link>
               </div>
               <div className="header-icon">
                  <Link to="/admin/notifications">  <i className="fa fa-bell"></i></Link>
                  <Link to="#" onClick={appFunction.logOut} title="Log Out"><i className="fa fa-sign-out"></i> </Link>
               </div>
            </div>
         </div>
      </nav>
   )
}

export const Sidebar = ({ active }) => {
   return (
      <aside>
         <div className="aside-login">
            <div className="site-logo">
               <img src={Logo} alt="Logo" />
            </div>
            <div className="svg_icon" onClick={appFunction.toggleSideBarWidth}>
               <AppIcons.menu />
            </div>
         </div>
         <ul className="aside-nav">
            <li className="has-children">
               <Link to="/admin/pre-users" className={active === '00' ? "active" : ""}>
                  <i className="fa fa-th-large"></i>
                  <span>Pre Registration</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin" className={active ? "" : "active"}>
                  <i className="fa fa-th-large"></i>
                  <span>Dashboard</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/holla-users" className={active === 1 ? "active" : ""}>
                  <i className="fa fa-user"></i>
                  <span>Users Management</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/jobs" className={active === 2 ? "active" : ""}>
                  <i className="fa fa-briefcase"></i>
                  <span>Jobs Managements</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/skills" className={active === 3 ? "active" : ""}>
                  <i className="fa fa-briefcase"></i>
                  <span>Skills Management</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/social-media" className={active === 4 ? "active" : ""}>
                  <i className="fa fa-commenting-o"></i>
                  <span>Social Media</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="#" className={active === 5 ? "active" : ""}>
                  <i className="fa fa-gift"></i>
                  <span>Rewards Management</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/console-users" className={active === 6 ? "active" : ""}>
                  <i className="fa fa-user-circle"></i>
                  <span>Add Users</span>
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/support" className={active === 7 ? "active" : ""}>
                  <i className="fa fa-commenting-o"></i>
                  <span>Support</span>
                  {/* {(appState.totalUsers.complaint > 0 || appState.totalUsers.liveChat > 0) && <div className="lv_cht_inctr">1</div>} */}
               </Link>
            </li>
            <li className="has-children">
               <Link to="/admin/settings" className={active === 8 ? "active" : ""}>
                  <i className="fa fa-cog"></i>
                  <span>Setup Management</span>
               </Link>
            </li>
         </ul>
      </aside>
   )
}

export const Breadcrumb = ({ name, fa }) => {
   const appState = useContext(AppContext).state
   return (
      <div className="breadcrumb">
         <div className="container-fluid p-0">
            <div className="row">
               <div className="col-5 text-gray mbl_ctl pl-0 ">
                  <div className="svg_icon font-bold cursor-default" onClick={() => History.goBack()}>
                     <AppIcons.arrow /> Back
                  </div>
                  <div className="svg_icon" onClick={helpers.showSideBar}>
                     <AppIcons.menu />
                  </div>
               </div>
               <div className="col-7 text-right">
                  {appState.flash && <div className="flash_mgs">{appState.flash}</div>}
                  <span>{name ? name : "Home"}</span>
               </div>
            </div>
         </div>
      </div>
   )
}

// for displaying modal notice
export const NoticeCard = ({ onYesClick, onNoClick, text, YesTitle, Notitle }) => (
   <div className="card _shadow">
      <div className="card-body d-block">
         <h6>{text}</h6>
         <div className="mt-3">
            <button className="btn btn-secondary mr-3" onClick={onYesClick ? onYesClick : () => console.log('No action')} >{YesTitle ?? 'Yes'}</button>
            <button className="btn btn-orange" onClick={onNoClick ? onNoClick : () => console.log('No action')}>{Notitle ?? 'No'}</button>
         </div>
      </div>
   </div>
)


// Modal conponents
export const Modal = (props = { onOverlayClick: Function, modalContainerStyle: {}, modalContentStyle: {} }) => (
   <div id="modal" onClick={e => {
      if (e.target.id === 'modal') {
         appFunction.closeModal()
         if (typeof props.onOverlayClick === "function") props.onOverlayClick()
      }
   }}>
      <div id="modal-container" style={props.modalContainerStyle ?? {}}>
         <div className="modal-content" id="modal-content" style={props.modalContentStyle ?? {}}>
            {props.children}
         </div>
      </div>
   </div>
)

// for not found notification
export const NotFound = (props = { text: String, style: Object }) => (
   <div className="not-found text-gray" style={props.style ?? { clear: 'both' }}>{props.text ?? 'Not Found'}</div>)

// For loading indication
export const PageLoader = (props = { style: Object }) => (
   <div className="not-found text-gray" style={props.style ?? { clear: 'both' }}>
      <img src={Loader} alt="loader" style={{ width: '100px' }} />
   </div>)

// footer
export const Footer = () => (
   <footer>
      <div className="footer">
         Holla || hollajobs.com
         &copy; {new Date().getFullYear()} Alright Reserved
      </div>
   </footer>
)

export const AppCard = (props = { bodyStyle: Object, cardStyle: Object, title: '', footer: '', component: '' }) => {
   return (
      <div className="card app_card" style={props.cardStyle ?? { clear: 'both' }} >
         {(props.title || props.component) &&
            <div className="container-fluid">
               <div className="row">
                  <div className="col-md p-0">{props.title}</div>
                  {props.component && <div className="col-md p-0"><div className="card_filter">{props.component}</div></div>}
               </div>
            </div>
         }
         <div className="card-body" style={props.bodyStyle ?? { clear: 'both' }}>{props.children}</div>
         {props.footer && <div className="card_footer">{props.footer}</div>}
      </div>
   )
}

export const Pagination = () => {
   const page = useContext(AppContext).state.pagination
   let totalPage = Math.ceil((page.total ? page.total : 1) / 50)
   return (
      <ul className="pagination">
         <div className="paging">
            <li>{`<`}</li>
            <form data-total={totalPage} data-active={page.active ? page.active : 1}>
               <input type="text" defaultValue={page.active ? page.active : 1} />
            </form>
            <li>{`>`}</li>
         </div>
         <div className="page-number">
            Total Pages: {totalPage}
         </div>
      </ul>
   )
}


export const AppTable = (props) => (
   <div className={props.noFlow ? "table-data noflow" : "table-data"}>
      <table>
         <thead>
            <tr>
               {props && props.headings && props.headings.map((e, i) => e &&
                  <th key={i}>{props.select && i === 0 ? <>
                     <input type="checkbox" className="slt-checkbox" />{e}</> : e}<span></span></th>)}
            </tr>
         </thead>
         <tbody>
            {props.children}
         </tbody>
      </table>
   </div>
)


export const ScreenTabsLink = ({ name, onClick, active }) => (
   <div className={active ? "active" : " "} onClick={onClick ? onClick : e => console.log('no-handler')}>{name}</div>
)
export const ScreenTabs = (props) => (<div className="screen-tab">{props.children}</div>)

export const TableMaterialIcon = {
   Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
   Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
   Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Delete: React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
   DetailPanel: React.forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
   Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
   Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
   Filter: React.forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
   FirstPage: React.forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
   LastPage: React.forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
   NextPage: React.forwardRef((props, ref) => <SkipNextRounded {...props} ref={ref} />),
   PreviousPage: React.forwardRef((props, ref) => <SkipPreviousRounded {...props} ref={ref} />),
   ResetSearch: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
   Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
   SortArrow: React.forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
   ThirdStateCheck: React.forwardRef((props, ref) => <Remove {...props} ref={ref} />),
   ViewColumn: React.forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const ArrowDropDownMenu = ({ menuList = [], onClick }) => (
   <div style={{ position: 'absolute', left: 0, top: 0, marginTop: 20 }}>
      <ul className="drp-dwn-menu">{menuList.map((e, i) => <li onClick={() => onClick(e)} key={i}>{e}</li>)}</ul>
   </div>
)