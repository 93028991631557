import React from 'react';
import * as AppComponents from './Components'
import helpers from '../js/functions/helpers'


export default (props) => {
   React.useEffect(e => {
      helpers.hideSideBar()
   }, []);
   return (
      <div>
         <div className="main-page">
            <div className="sidebar_pane">
               <AppComponents.Sidebar active={props.sActive} />
            </div>
            <div>
               <AppComponents.Header />
               <AppComponents.Breadcrumb fa={props.bfa} name={props.bname} />
               <div className="container-fluid inner-h">
                  {props.children}
               </div>
               <AppComponents.Footer />
            </div>
         </div>
      </div>
   )
}
