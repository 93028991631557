import { appState } from "../Context"
import ReducerAction from "../Reducer_actions"
import appFunction from "./appFunction"

const helpers = {}
// const authService = 'http://167.71.7.56:8000';
const authService = 'https://api.hollajobs.com/userservice';
const adminUserService = 'https://api.hollajobs.com/admin-userservice'
// const adminUserService = 'http://167.71.7.56:7000'
// const adminAppService = 'http://localhost:7001'
const adminAppService = 'https://api.hollajobs.com/admin-appservice'
const walletService = 'https://api.hollajobs.com/walletservice'

helpers.url = {}
helpers.url.submitOTP = `${authService}/auth/otp`
helpers.url.password = `${authService}/auth/password`
helpers.url.verifyUser = `${authService}/auth/verify-user`
helpers.url.verifyUserJWT = `${authService}/auth/verify-jwtoken`
helpers.url.loginUser = `${authService}/auth/login`


helpers.url.getHollaJobsAnalysis = `${adminAppService}/analysis/usersAndJobs`
helpers.url.getHollaJobsAnalysisPreUsers = `${adminAppService}/analysis/preUsers`
helpers.url.getAnalysisCount = `${adminAppService}/analysis/totalCountUsersAndJobs`
helpers.url.getAnalysisCountPreUsers = `${adminAppService}/analysis/totalCountPreUser`
helpers.url.getSkills = `${adminAppService}/skills`
helpers.url.addSkill = `${adminAppService}/skills/addSkill`
helpers.url.editSkill = `${adminAppService}/skills/editSkill`
helpers.url.deleteSkill = `${adminAppService}/skills/deleteSkill`
helpers.url.getSkillInfo = `${adminAppService}/skills/skillInfo`
helpers.url.getSkillCategory = `${adminAppService}/skills/category`
helpers.url.getHollaJobsUsers = `${adminAppService}/users`
helpers.url.getAllJobsApp = `${adminAppService}/users/jobsApp`
helpers.url.getAllJobsUSSD = `${adminAppService}/users/jobsUSSD`
helpers.url.getWorkerSkill = `${adminAppService}/users/workerSkills`
helpers.url.getUserJobData = `${adminAppService}/users/userJobsApp`
helpers.url.approveSkill = `${adminAppService}/users/approveSkill`
helpers.url.getSettings = `${adminAppService}/app/getSettings`
helpers.url.addSettings = `${adminAppService}/app/addSettings`
helpers.url.setUserType = `${adminAppService}/app/setUserType`

helpers.url.createUserURL = `${adminUserService}/auth/registerUser`

helpers.url.submitVerifiedSkill = `${adminUserService}/user/verify_skill`
helpers.url.editUserURL = `${adminUserService}/user/editUser`
helpers.url.confirmAdmin = `${adminUserService}/user/confirmUser`
helpers.url.deleteUserURL = `${adminUserService}/user/deleteUser`
helpers.url.getDashboardUserURL = `${adminUserService}/user/getUsers`
helpers.url.resetPassword = `${adminUserService}/user/resetPass`
helpers.url.getUserActivity = `${adminUserService}/user/getUserActivity`

helpers.url.getUserWalletBalance = `${walletService}/wallets/balance`
helpers.url.getUserWalletTransaction = `${walletService}/wallets/transaction`

helpers.url.changeDashboardUserPassword = `${adminUserService}/user/changeDefaultPass`
helpers.url.uploadProfilePicture = `${adminUserService}/fileUpload/uploadProfilePicture`
helpers.url.deleteProfilePicture = `${adminUserService}/user/deleteProfilePicture`
helpers.url.uploadSkillCertificate = `${adminUserService}/fileUpload/uploadSkillCertificate`
helpers.url.profileImage = adminUserService + '/'


// AJAX for sending request
helpers.sendRequest = (Obj, callback) => {
   if (!Obj instanceof Object) return
   if (Obj.url === undefined) return
   var headers = new Headers();
   headers.append('Content-Type', 'application/json');
   headers.append('Accept', 'application/json');
   headers.append('Authorization', `Bearer ${Obj?.token ?? appState?.account?.jToken}`)
   if (Obj.METHOD === 'GET') {
      fetch(Obj.url).then(res => res.json().then(data => callback(data)))
         .catch((err) => callback({ "error": "Could not connect to the server" }))
   } else {
      let option = { headers: headers, method: Obj.method ?? 'GET' }
      option.body = Obj.body ? JSON.stringify(Obj.body) : null
      fetch(Obj.url, option).then(res => res.json().then(data => callback(data)))
         .catch((err) => console.log(err))
         .catch((err) => callback({ "error": "Could not connect to the server" }))
   }
}

// for triming date
helpers.showPostDate = (date) => {
   if (!date) return
   return date.substr(0, 19).replace("T", " ")
}
let MonthDays = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
helpers.MonthDays = MonthDays;
helpers.calculatePostDate = (date, format) => {
   if (!date) return
   let DateNow = new Date() // current date
   let postedDate = new Date(date); // date posted
   let postedTime = date.indexOf('T') > -1 ? date.substr(date.indexOf("T") + 1, 5) : "" //get the time
   let postedMnth = MonthDays[postedDate.getMonth()] //get the month
   let postedYear = postedDate.getFullYear(); //get the year
   let postedDay = postedDate.getDate() //get the date of the month
   let rDate = null
   let sDate = ((DateNow - postedDate) / 1000) //get the seconds of in the date
   let outputDate = null

   if (sDate >= 86400) { // if days
      rDate = parseInt(Math.round(sDate / 86400))
      switch (format) {
         case 'date-only':
            outputDate = rDate === 1 ? 'Yesterday' : `${postedMnth} ${postedDay}, ${postedYear}`
            break;
         default:
            outputDate = rDate === 1 ? 'Yesterday ' + postedTime : `${postedMnth} ${postedDay}, ${postedYear} ${postedTime}`
      }
   } else if (sDate >= 3600) { //if hours
      rDate = parseInt(Math.round(sDate / 3600))
      switch (format) {
         case 'date-only':
            outputDate = 'Today'
            break;
         default:
            outputDate = rDate + 'hr ago'
      }
   } else if (sDate >= 60) { //if minuntes
      rDate = parseInt(Math.round(sDate / 60))
      switch (format) {
         case 'date-only':
            outputDate = 'Today'
            break;
         default:
            outputDate = rDate + 'min ago'
      }
   } else { //if seconds
      rDate = Math.ceil(sDate)
      switch (format) {
         case 'date-only':
            outputDate = 'Today'
            break;
         default:
            outputDate = sDate < 1 ? '1sec ago' : rDate + 'sec ago'
      }
   }
   return outputDate
}

helpers.getPostTime = (data) => {
   return !data ? null : data.substr(data.indexOf('T') + 1, 5)
}
// function to show moredata
helpers.showEllipsisMenu = (e) => {
   if (e.type === 'click') {
      e.currentTarget.children[1].classList.remove('d-none')
   } else {
      e.currentTarget.classList.add('d-none')
   }
}

helpers.hideSideBar = () => {
   if (window.innerWidth < 768) {
      document.querySelector('.sidebar_pane').classList.add('d-none')
   }
}
// function to unhide the sidebar
helpers.showSideBar = () => {
   document.querySelector('.sidebar_pane').classList.remove('d-none')
}
//function to open/hide the user data preview card
helpers.togglePreviewCard = (e) => {
   let cTarget = e.currentTarget
   let icon = cTarget.children[0].classList.contains('fa-chevron-down')
   if (icon) {
      e.currentTarget.parentElement.parentElement.children[1].classList.add('d-none')
      e.currentTarget.children[0].classList.remove('fa-chevron-down')
   } else {
      e.currentTarget.parentElement.parentElement.children[1].classList.remove('d-none')
      e.currentTarget.children[0].classList.add('fa-chevron-down')
   }
}
//function to open/hide the user data preview card
helpers.toggleJobMoreData = (e, id) => {
   let cTarget = e.currentTarget
   let icon = cTarget.children[0].classList.contains('fa-chevron-down')
   if (icon) {
      e.currentTarget.children[0].classList.remove('fa-chevron-down')
      document.querySelector(`#job${id}`).classList.add('d-none')
   } else {
      e.currentTarget.children[0].classList.add('fa-chevron-down')
      document.querySelector(`#job${id}`).classList.remove('d-none')
   }
}
//function to switch screen view
helpers.switchScreenView = (screen, data, clearData = null) => {
   appState.modal.screen = screen ?? null
   appState.modal.screenData = data ?? null

   if (clearData === "users") {
      appState.userData = null
   }
   appFunction.updateState()
}
//function to clear ticker chat view
helpers.clearTickerView = () => {
   appState.dData.type = null
   appFunction.updateState()
}
//function to set skill status
helpers.setWorkerSkillStatus = (e) => {
   e.preventDefault()
   let userID = e.target.getAttribute('data-user')
   let id = e.target.getAttribute('data-id')
   let status = e.target.checked ? 'approved' : 'pending'
   appState.modal.type = ReducerAction.modal.verifyWorkerSkill
   appState.modal.data = { user_id: userID, skill_id: id, status }
   appFunction.updateState()
}

helpers.runAnalysisYear = (minYear, maxYear) => {
   let result = []
   let minHere = minYear - 1;
   for (let i = maxYear; i > minHere; i--) {
      result.push(i)
   }
   return result;
}

helpers.runChatAnalysis = (e, type) => {
   e.preventDefault()
   let { month, year } = e.target.elements
   if (month.value || year.value) {
      let lastDay = new Date(year.value, month.value, 0).getDate();
      appState.indicator = ReducerAction.indicator.dashboardChart
      if (type === "pre_users") {
         appFunction.getAnalysisPreUser(`${year.value}-${month.value}-${lastDay}`, true)
      } else {
         appFunction.getAnalysis(`${year.value}-${month.value}-${lastDay}`, true)
      }
      appFunction.updateState()

   }
}

helpers.formatMoney = (amount = '') => {
   return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

helpers.runJobStage = (statusCode) => {
   switch (statusCode) {
      case 0:
         return 'Pending';
      case 1:
         return 'Worker accepted request';
      case 2:
         return 'Worker sent invoice';
      case 3:
         return "Client made payment";
      case 4:
         return "Worker started job";
      case 5:
         return "Client confirmed job start";
      case 6:
         return "Worker ended job";
      case 7:
         return "Job Completed";
      default:
         return 'Job Cancelled';
   }
}

helpers.timeZone = (date) => {
   var dbDate = new Date(date)
   var timeOffsetInMS = dbDate.getTimezoneOffset() * 60000;
   dbDate.setTime(dbDate.getTime() - timeOffsetInMS);
   return date
}


//function to which 

export default helpers;

