import React, { createContext, useReducer } from 'react';
import Reducers from './Reducers';

const AppContext = createContext();
export let dispatcher;
export let appState;
export const AppProvider = (props) => {
   const initialState = {
      auth: null,
      error: null,
      modal: {},
      indicator: null,
      flash: null,
      users: null, // for appusers
      totalUsers: {}, // holding multiple thing, details at the bottom
      jobs: {}, // has app and ussd properties
      dData: {}, // for pushing any temporary data
      userData: null, // for a single Holla user data
      // filterData: {}, //for filtering data to display
      message: null,
      activeChat: [],
      account: {},
      cUpdate: Math.random()
   }
   const [state, dispatch] = useReducer(Reducers, initialState)
   dispatcher = dispatch
   appState = state
   return <AppContext.Provider value={{ state }}>
      {props.children}
   </AppContext.Provider>
}
export default AppContext

// totalUsers properties :
// clients = for total number of client 
// workers = for total number of worker 
//analysis = for analysis chat data on the dasboard
// today = for total number of worker,client,job the current day,month n year and show.
//liveChat = for holding numbers of unread chats
//liveChatPage= the total number of pages
