import React from 'react';
import Layout from '../Layout';
import * as AppComponent from '../Components';
import AppIcons from '../icons';
import appFunction from '../../js/functions/appFunction';


const RewardForm = () => (
   <AppComponent.AppCard title="Add New Reward">
      <div className="row w-100">
         <div className="col-md-5">
            <h6 className="c-title">Reward Title</h6>
            <input className="form-control" />
         </div>
         <div className="col-md-5">
            <h6 className="c-title">Reward Point</h6>
            <input type="number" className="form-control" />
         </div>
         <div className="col-md-2">
            <h6 className="c-title invisible">Date</h6>
            <button className="btn btn-orange mr-2">Save</button>
            <button className="btn btn-secondary">Reset</button>
         </div>
      </div>
   </AppComponent.AppCard>
)


const RewardSearch = () => (
   <>
      <select className="filter-control" style={{ maxWidth: '230px' }}>
         <option value="all">Select Reward Status</option>
         <option value="client">Pending</option>
         <option value="worker">Redemeed</option>
      </select>
      <input type="text" className="filter-control" style={{ maxWidth: '230px' }} placeholder="Search Reward" />
   </>)

const RewardsRow = ({ e, i }) => (
   <tr onClick={appFunction.closeMoreUserDataMenu}>
      <td>11</td>
      <td>Referals</td>
      <td>0.5</td>
      <td>2020/04.02 12:00</td>
      <td className="more_user_data">
         <div className="svg_icon more_user_data">
            <AppIcons.edit />
            <AppIcons.delete />
         </div>
      </td>
   </tr>
)
const Rewards = () => (
   <AppComponent.AppCard title="Rewards Registered" padding="no">
      <AppComponent.AppTable headings={["Roll", "Reward Title", "Reward Points", "Submitted On", "Action"]}>
         {['Woker', 'Client', 'Client', 'Client', 'Worker', 'Worker', 'Client', 'Worker', 'Client'].map((e, i) => <RewardsRow key={i} e={e} i={i} />)}
      </AppComponent.AppTable>
   </AppComponent.AppCard>
)

export default () => (
   <Layout bname="Reward" sActive={5}>
      <RewardForm />
      <Rewards />
   </Layout>
)