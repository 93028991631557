import React, { useContext, useRef } from 'react';
import * as AppComponents from './Components';
import Layout from './Layout';
import helpers from '../js/functions/helpers';
import AppContext from '../js/Context';
import appFunction from '../js/functions/appFunction';
import AppIcons from './icons';
import ReducerAction from '../js/Reducer_actions';


const PostCard = ({ title, number, fa, bg, today }) => (
   <div className="col-lg-6 col-xl-3">
      <AppComponents.AppCard>
         <div className="numba-card">
            <div className="numba-icon" style={bg ? { backgroundColor: bg } : { clear: 'both' }}>
               <i className={`fa fa-${fa}`}></i>
            </div>
            <h6 style={bg ? { color: bg } : { color: '#000' }}>{title}</h6>
         </div>
         <div>
            <div className="numba-fig">
               <span>{isNaN(number) ? 0 : number}</span>
               <div className="d-inline-flex">
                  <span className={today > 0 ? "indica add" : "indica"}></span>
                  <small>({today > 0 ? '+' + today : today})</small>
               </div>
            </div>
         </div>
      </AppComponents.AppCard>
   </div>
)

const AnalysisBigCard = ({ loader, defaultMonth, defaultYear }) => (
   <AppComponents.AppCard bodyStyle={{ overflow: 'visible' }}>
      <div className="chrt-an">
         <h6 className="c-title">Growth Analysis</h6>
         <div className="chrt-indica1">
            <div>
               <span>Workers</span>
               <div className="chrt-idica bg-yellow"></div>
            </div>
            <div>
               <span>Clients</span>
               <div className="chrt-idica bg-primary"></div>
            </div>
            <div>
               <span>Jobs</span>
               <div className="chrt-idica bg-red"></div>
            </div>
         </div>
         {/* onClick={e => helpers.switchScreenView(ReducerAction.screen.dashboardStat)} */}
         <div className="font-bold ">
            <form onSubmit={(e) => helpers.runChatAnalysis(e, "pre_users")}>
               <select name="month" className="mr-2" defaultValue={defaultMonth} style={{ height: 30 }}>
                  {helpers.MonthDays.map((e, i) => <option key={i} value={i <= 8 ? '0' + (i + 1) : (i + 1)}>{e}</option>)}
               </select>
               <select name="year" defaultValue={defaultYear} style={{ height: 30 }}>
                  {helpers.runAnalysisYear(2020, new Date().getFullYear()).map((e, i) => <option key={i} value={e}>{e}</option>)}
               </select>
               <button disabled={loader === ReducerAction.indicator.dashboardChart} style="submit"
                  style={{ paddingLeft: 8, paddingRight: 8 }} className="btn bg-primary text-white btn-sm ml-2">Go</button>
            </form>
         </div>
      </div>
      {loader === ReducerAction.indicator.dashboardChart && <div style={{ position: 'absolute', width: '100%', top: 0, marginTop: '50px' }} >
         <AppComponents.PageLoader />
      </div>
      }
      <canvas id="analysis-graph" height="400"></canvas>
   </AppComponents.AppCard>
)


export default () => {
   const appState = useContext(AppContext).state
   let analysisD = appState.totalUsers?.today?.show
   React.useEffect(() => {
      appFunction.getAnalysisPreUser(undefined, true)
      appFunction.getAnalysisCountPreUsers(true)
      // return function () {
      //    helpers.switchScreenView()
      // }
   }, [])
   return (
      <Layout sActive={'00'}>
         <div className="inner-h">
            {analysisD !== true ? <AppComponents.PageLoader /> : analysisD === true ?
               <React.Fragment>
                  <div className="row mt-3">
                     <PostCard title="Total Users" number={appState.totalUsers?.workers + appState.totalUsers?.clients ?? 0} fa="users" bg="#358DCE"
                        today={appState.totalUsers.today?.worker + appState.totalUsers.today?.client ?? 0} />
                     <PostCard title="Workers" number={appState.totalUsers?.workers ?? 0} fa="user-md" bg="#ffdb9a" today={appState.totalUsers?.today?.worker ?? 0} />
                     <PostCard title="Clients" number={appState.totalUsers?.clients ?? 0} fa="user" bg="#358DCE" today={appState.totalUsers.today?.client ?? 0} />
                     <PostCard title="Jobs" number={appState.totalUsers?.jobs ?? 0} fa="briefcase" bg="red" today={appState.totalUsers.today?.job ?? 0} />
                  </div>
                  <div className="row">
                     <div className="col">
                        <AnalysisBigCard defaultMonth={appState.totalUsers?.today?.month} defaultYear={appState.totalUsers?.today?.year}
                           loader={appState.indicator} date={appState.totalUsers.today} />
                     </div>
                  </div>
               </React.Fragment> :
               <AppComponents.NotFound text="No Users" />
            }
         </div>
      </Layout>
   )
}